import styled from "styled-components";

export const StyledPeople = styled.div`
  padding: 50px 0;
  text-align: center;
`;

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: bold;
`;

export const SubHeadline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
  width: 50%;
  margin: 10px auto 15px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const List = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 100px;
  margin: 100px 100px 0px 100px;
  justify-content: space-around;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    margin: 100px 25px 0px 25px;
  }
`;

export const Person = styled.div`
  width: calc(25% - 50px);
  position: relative;
  padding: 125px 0px 20px 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 50px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`;

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.hint};
`;

export const Image = styled.img`
  position: absolute;
  top: -75px;
  width: 150px;
  border-radius: 150px;
  left: 50%;
  margin-left: -75px;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin: 25px 0px;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Value = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 5px 0px;
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const ValueLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: none;
`;
