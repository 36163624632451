import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./Global.Styled";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import Home from "./components/Home/Home";
import ETLProtax from "./components/ETLProtax/ETLProtax";
import "./global.scss";
import Career from "./components/Career/Career";
import Services from "./components/Services/Services";
import Imprint from "./components/Imprint/Imprint";
import DataProtection from "./components/DataProtection/DataProtection";
import Contact from "./components/Contact/Contact";
import Locations from "./components/Locations/Locations";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="etl-protax" element={<ETLProtax />} />
            <Route path="karriere" element={<Career />} />
            <Route path="leistungen" element={<Services />} />
            <Route path="standorte" element={<Locations />} />
            <Route path="kontakt" element={<Contact />} />
            <Route path="impressum" element={<Imprint />} />
            <Route path="datenschutz" element={<DataProtection />} />
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
}

export default App;
