import styled from "styled-components";

export const StyledImprint = styled.div`
  padding-bottom: 50px;
`;

export const Content = styled.div`
  padding: 75px 0px;
`;

export const HeroContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeroContentWrapper = styled.div`
  text-align: center;
`;
export const Title = styled.div`
  font-size: 70px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 70px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const P = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: underline;
`;
