import styled from "styled-components";

export const StyledTemplate = styled.div`
  text-align: center;
`;

export const Subtitle = styled.div`
  font-size: 25px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 20px;
  }
`;
export const Title = styled.div`
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ isSingle }) => (isSingle ? "100px" : "70px")};
  line-height: ${({ isSingle }) => (isSingle ? "100px" : "70px")};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ isSingle }) => (isSingle ? "50px" : "30px")};
    line-height: ${({ isSingle }) => (isSingle ? "50px" : "30px")};
  }
`;
export const By = styled.div`
  font-size: 30px;
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-top: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 20px;
  }
`;
export const Others = styled.div`
  margin-top: 10px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ isSingle }) => (isSingle ? "100px" : "60px")};
  line-height: ${({ isSingle }) => (isSingle ? "100px" : "60px")};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ isSingle }) => (isSingle ? "50px" : "30px")};
    line-height: ${({ isSingle }) => (isSingle ? "50px" : "30px")};
  }
`;
export const CTA = styled.a`
  margin-top: 25px;
  display: inline-block;
  margin-left: auto;
  background-color: ${({ theme }) => theme.button};
  padding: 7.5px 30px;
  background-color: ${({ theme }) => theme.color.background.red};
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: none;
`;
