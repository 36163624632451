import styled from "styled-components";

export const StyledHome = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: auto 100%;
  }
`;

export const Logo = styled.img`
  height: 125px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    width: 90vw;
  }
`;

export const Background = styled.div`
  background-size: 100% 100%;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-image: url(${({ image }) => image});
  background-position: center center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-size: auto 100%;
  }
`;

export const Split = styled.div`
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
  width: 90%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const SplitItem = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    &:first-child {
      width: 100%;
    }
  }
`;

export const Seperator = styled.div`
  height: 150px;
  width: 1px;
  background-color: white;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 150px;
    height: 1px;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 50px;
  z-index: 2;
`;

export const Page = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
  background-color: white;
  `}
`;
