import styled from "styled-components";

export const StyledValues = styled.div`
  padding: 150px 0px 150px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0px;
  }
`;

export const Split = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const ImageSection = styled.div`
  width: 50%;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    height: 300px;
  }
`;

export const PointSection = styled.div`
  width: 50%;
  padding: 75px 50px;
  gap: 25px;
  box-sizing: content-box;
  height: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    height: auto;
    padding: 50px 0px;
  }
`;

export const PointSectionForDesktop = styled(PointSection)`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const PointSectionForMobile = styled(PointSection)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;

export const HeadlineContainer = styled.div`
  text-align: center;
`;

export const MainHeadline = styled.div`
  width: 200px;
  text-align: center;
  font-family: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-top: 15px;
  line-height: 40px;
`;

export const Card = styled.div`
  width: 350px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  border-radius: 20px;
  padding: 10px;
  padding-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
    padding: 0px;
    margin-bottom: 50px;
  }
`;

export const CardHeadline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  margin: 10px 0px;
`;

export const CardText = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: justify;
`;
