import styled, { keyframes } from "styled-components";

const zoomIn = keyframes`
from {
    transform: scale(0.9);
    opacity: 0;
  }
  
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const zoomOut = keyframes`
from {
    transform: scale(1.1);
    opacity: 0;
  }
  
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const fadeIn = keyframes`
from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

const fadeInLeft = keyframes`
from {
    transform: translateX(-10%);
    opacity: 0;
  }
  
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;
const fadeInRight = keyframes`
from {
    transform: translateX(10%);
    opacity: 0;
  }
  
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`;
const fadeInUp = keyframes`
from {
    transform: translateY(-10%);
    opacity: 0;
  }
  
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;
const fadeInDown = keyframes`
from {
    transform: translateY(10%);
    opacity: 0;
  }
  
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`;
const slideInRightAnimation = keyframes`
from {
    transform: translateX(10%);
  }

  to {
    transform: translateX(0%);
  }
`;
const slideInDownAnimation = keyframes`
from {
    transform: translateY(-10%);
  }

  to {
    transform: translateY(0%);
  }
`;

export const float = keyframes`
    0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
`;

export const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

export const blinkCaret = keyframes`
  0% {
    opacity: 0;
  }
`;

const getAnimation = (animation) => {
  switch (animation) {
    case "fadeInLeft":
      return fadeInLeft;
    case "fadeInRight":
      return fadeInRight;
    case "fadeInUp":
      return fadeInUp;
    case "fadeInDown":
      return fadeInDown;
    case "fadeIn":
      return fadeIn;
    case "slideInRight":
      return slideInRightAnimation;
    case "slideInDown":
      return slideInDownAnimation;
    case "float":
      return float;
    case "zoomIn":
      return zoomIn;
    case "zoomOut":
      return zoomOut;
    default:
      return "";
  }
};

export const StyledAnimated = styled.div`
  animation: ${({ duration }) => (duration ? `${duration}s` : "1s")}
    ${({ infinite }) => infinite && "infinite"}
    ${({ animation }) => getAnimation(animation)};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    animation: ${({ disabledOnMobile }) => disabledOnMobile && "none"};
  }
`;

export const StyledAnimatedTyping = styled.div`
  animation: ${typing} ${({ duration }) => (duration ? `${duration}s` : "1s")}
    steps(50);
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  /* BLINKER IS DISABLED BECAUSE COLOR PARAMETER IS MISSING */
  /* &:after {
    content: '';
    width: 5px;
    height: ${({ theme }) => theme.font.size.lg};
    margin-left: 5px;
    background: ${({ theme }) => theme.color.font.secondary};
    display: inline-block;
    animation: ${blinkCaret} 1s steps(2) infinite;
  } */
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ disabledOnMobile }) =>
      disabledOnMobile && "animation: none; white-space: normal;"};
  }
`;

export const Mock = styled.div`
  margin: 0 auto;
  visibility: hidden;
`;
