import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledOpenPositions,
  Headline,
  StyledItem,
} from "./OpenPositions.Styled";
import Positions from "./Positions/Positions";
import ItemContent from "./ItemContent/ItemContent";

const Item = ({ position }) => {
  return (
    <StyledItem>
      <ItemContent position={position} />
    </StyledItem>
  );
};

const OpenPositions = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  const getItem = (position) => {
    return { title: position.title, content: <Item position={position} /> };
  };

  return (
    <StyledOpenPositions>
      <Headline id="positions">
        {translation.career.openPositions.headline}
      </Headline>
      <Container>
        <Positions
          items={translation.career.openPositions.positions.map(getItem)}
        />
      </Container>
    </StyledOpenPositions>
  );
};

export default OpenPositions;
