import styled from "styled-components";

export const StyledPositions = styled.div`
  ${({ theme }) => theme?.components?.accordion?.accordion};
  margin: 50px 0;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  ${({ theme }) => theme?.components?.accordion?.flex};
`;
