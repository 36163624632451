import { StyledSlider, Slide, Navigator } from "./Slider.Styled";
import arrowLeft from "../../../assets/image/carousel/arrow-left.svg";
import arrowRight from "../../../assets/image/carousel/arrow-right.svg";
import Animated from "../../SubComponents/Animated/Animated";
import Indicators from "./Indicators/Indicators";
import { getUUID } from "../../../utility";

const Slider = ({ elements, index, indexCallback }) => {
  const getElement = (i, direction) => {
    const element = elements[i];
    return (
      <Slide className={direction}>
        <Animated
          showIfVisible={false}
          key={getUUID()}
          animation={
            direction === "current"
              ? "fadeInRight"
              : direction === "next"
              ? "fadeInLeft"
              : "slideInRight"
          }
        >
          {element}
        </Animated>
      </Slide>
    );
  };

  const getNextElement = () =>
    getElement(index === elements.length - 1 ? 0 : index + 1, "next");
  const getCurrentElement = () => getElement(index, "current");
  const getPreviousElement = () =>
    getElement(index === 0 ? elements.length - 1 : index - 1, "previous");

  const next = () => {
    const nextIndex = index === elements.length - 1 ? 0 : index + 1;
    indexCallback(nextIndex);
  };

  const previous = () => {
    const previousIndex = index === 0 ? elements.length - 1 : index - 1;
    indexCallback(previousIndex);
  };

  return (
    <StyledSlider>
      {getPreviousElement()}
      {getCurrentElement()}
      {getNextElement()}
      <Navigator className="next" onClick={next} src={arrowRight} />
      <Navigator className="previous" onClick={previous} src={arrowLeft} />
      <Indicators
        elementsLength={elements.length}
        index={index}
        indexCallback={indexCallback}
      />
    </StyledSlider>
  );
};

export default Slider;
