import styled from "styled-components";

export const Arrow = styled.img`
  width: 40px;
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 60%;
  position: absolute;
  z-index: 3;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 70%;
  }
`;

export const ChildWrapper = styled.div`
  opacity: ${({ opacity }) => opacity};
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
