import {
  StyledCard,
  Headline,
  Text,
  Content,
  Flag,
  Background,
} from "./Card.Styled";
import flag from "../../../assets/image/flag-white-filled.png";

const Card = ({ card, index, isActive, image }) => {
  return (
    <StyledCard isActive={isActive}>
      {isActive && <Background bg={image} />}
      <Content isActive={isActive}>
        <Flag src={flag} />
        <Headline>
          {`${index + 1}. `}
          {card.headline}
        </Headline>
        <Text>{card.text}</Text>
      </Content>
    </StyledCard>
  );
};

export default Card;
