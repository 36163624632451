import { StyledItem, Header, Body, OpenIcon, Icon, Title } from "./Item.Styled";
import React from "react";
import openIcon from "../../../../../assets/image/plus-white.png";
import closeIcon from "../../../../../assets/image/plus-black.png";

const Item = ({ title, content, open, toggle, id }) => {
  return (
    <StyledItem id={id}>
      <Header open={open} onClick={toggle}>
        <Title>{title}</Title>
        <OpenIcon open={open}>
          {open ? <Icon src={openIcon} /> : <Icon src={closeIcon} />}
        </OpenIcon>
      </Header>
      {open && <Body>{content}</Body>}
    </StyledItem>
  );
};

export default Item;
