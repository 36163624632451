const Button = ({
  text,
  onClick,
  href,
  inverted = false,
  type = "default",
  className = "",
  disabled = false,
  light = false,
}) => {
  const classes = "py-1 px-10 text-white bg-red outline-none";

  if (!!href) {
    return (
      <a className={`${classes} ${className}`} href={href}>
        {text}
      </a>
    );
  }
  return (
    <button
      className={`${classes} ${className}`}
      type={type === "submit" ? "submit" : "default"}
      onClick={type !== "submit" ? onClick : null}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
