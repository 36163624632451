import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import {
  StyledItemContent,
  Prop,
  Key,
  Value,
  ApplyFormWrapper,
  Description,
  DescriptionItem,
  Enumeration,
  CTA,
} from "./ItemContent.Styled";
import ApplyForm from "./ApplyForm/ApplyForm";
import { getUUID } from "../../../../utility";

const ItemContent = ({ position }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const {
    title,
    description,
    tasks,
    expectations,
    offerings,
    applicationLink,
  } = position;
  return (
    <StyledItemContent>
      <Description>
        {description.map((d) => (
          <DescriptionItem key={getUUID()}>{d}</DescriptionItem>
        ))}
      </Description>
      {Array.isArray(tasks) && (
        <Prop>
          <Key>{translation.career.openPositions.tasks}</Key>
          <Value>
            <Enumeration>
              {tasks.map((t) => (
                <li key={getUUID()}>{t}</li>
              ))}
            </Enumeration>
          </Value>
        </Prop>
      )}
      {Array.isArray(offerings) && (
        <Prop>
          <Key>{translation.career.openPositions.offerings}</Key>
          <Value>
            <Enumeration>
              {offerings.map((o) => (
                <li key={getUUID()}>{o}</li>
              ))}
            </Enumeration>
          </Value>
        </Prop>
      )}
      {expectations && (
        <Prop>
          <Key>{translation.career.openPositions.expectations}</Key>
          <Value>{expectations}</Value>
        </Prop>
      )}
      {!!applicationLink && (
        <CTA href={applicationLink} target="_blank" rel="noreferrer">
          {translation.career.reasons.cta}
        </CTA>
      )}
      {!applicationLink && (
        <ApplyFormWrapper>
          <ApplyForm title={title} />
        </ApplyFormWrapper>
      )}
    </StyledItemContent>
  );
};

export default ItemContent;
