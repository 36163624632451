import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledContinents,
  List,
  Item,
  Headline,
  HeadlineLink,
  SVGMapWrapper,
  CountryList,
  Country,
} from "./Continents.Styled";
import { Container } from "@think-internet/ui-components";
import world from "@svg-maps/world";
import { SVGMap } from "react-svg-map";
import { getUUID } from "../../../utility";

const Continents = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  const getLink = (name) => {
    return `https://www.etl-global.com/${name}`;
  };

  const redirect = (name) => window.open(getLink(name), "_blank");

  const getAvailableCountries = () => {
    const countries = [];
    world.locations.forEach((l) => {
      if (
        translation.locations.continents.highlightedCountries.includes(l.id)
      ) {
        if (l.name === "Lao People's Democratic Republic") {
          l.name = "Laos";
        }
        countries.push(l.name);
      }
    });
    return countries;
  };

  const isSelectedCountry = (id) =>
    translation.locations.continents.highlightedCountries.includes(id);

  return (
    <StyledContinents>
      <Container>
        <Headline>
          {translation.locations.continents.headlineFirst}
          <HeadlineLink
            href="https://www.etl-global.com/"
            target="_blank"
            rel={"noreferrer"}
          >
            {translation.locations.continents.headlineSecond}
          </HeadlineLink>
        </Headline>
        <List>
          {translation.locations.continents.items.map((c) => (
            <Item href={c.url} target="_blank" key={getUUID()}>
              {c.label}
            </Item>
          ))}
        </List>
        <SVGMapWrapper>
          <SVGMap
            onLocationClick={(e) =>
              isSelectedCountry(e.target.id)
                ? redirect(e.target.ariaLabel)
                : null
            }
            isLocationSelected={(location) => isSelectedCountry(location.id)}
            map={world}
          />
        </SVGMapWrapper>
        <CountryList>
          {getAvailableCountries().map((c) => (
            <Country href={getLink(c)} target="_blank" key={getUUID()}>
              {c}
            </Country>
          ))}
        </CountryList>
      </Container>
    </StyledContinents>
  );
};

export default Continents;
