import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { StyledMap, GoogleMapWrapper } from "./Map.Styled";
import GoogleMap from "./GoogleMap/GoogleMap";

const Map = ({ activeIndex }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  const mapsOptions = { zoom: 10, center: { lat: 49.8325, lng: 9.192951 } };
  const markers = translation.locations.boxes.items.map((loc, i) => ({
    coordinates: loc.coordinates,
    active: activeIndex === i,
  }));
  return (
    <StyledMap>
      <GoogleMapWrapper>
        <GoogleMap options={mapsOptions} markers={markers} />
      </GoogleMapWrapper>
    </StyledMap>
  );
};

export default Map;
