import {
  Split,
  ImageSection,
  PointSectionForDesktop,
  PointSectionForMobile,
  StyledValues,
  HeadlineContainer,
  MainHeadline,
  Card,
  CardHeadline,
  CardText,
} from "./Values.Styled";
import imageSectionBg from "../../../../assets/image/etl-protax/goals/values/imagesection-bg.png";
import { getUUID } from "../../../../utility";
import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import Flag from "../../../SubComponents/Flag/Flag";
import Logo from "../../../SubComponents/Logo/Logo";
import Carousel from "../../../SubComponents/Carousel/Carousel";
import { Container } from "@think-internet/ui-components";

const Values = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledValues>
      <Split>
        <ImageSection bg={imageSectionBg}>
          <HeadlineContainer>
            <Logo height="35px" />
            <MainHeadline>
              {translation.etlProtax.goals.values.headline}
            </MainHeadline>
          </HeadlineContainer>
        </ImageSection>
        <PointSectionForDesktop>
          <Carousel
            autoplay={false}
            autoplay_speed={5000}
            disable_box_shadow={true}
          >
            {translation.etlProtax.goals.values.points.map((card) => (
              <Card key={getUUID()}>
                <Flag width="50px" />
                <CardHeadline>{card.headline}</CardHeadline>
                <CardText>{card.text}</CardText>
              </Card>
            ))}
          </Carousel>
        </PointSectionForDesktop>
        <PointSectionForMobile>
          <Container>
            {translation.etlProtax.goals.values.points.map((card) => (
              <Card key={getUUID()}>
                <Flag width="50px" />
                <CardHeadline>{card.headline}</CardHeadline>
                <CardText>{card.text}</CardText>
              </Card>
            ))}
          </Container>
        </PointSectionForMobile>
      </Split>
    </StyledValues>
  );
};

export default Values;
