import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { Container } from "@think-internet/ui-components";
import { getUUID } from "../../../utility";

const { StyledBoxes, Box, Item, ItemLink } = require("./Boxes.Styled");

const Boxes = ({ setActiveBox, activeBox }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Container>
      <StyledBoxes>
        {translation.locations.boxes.items.map((b, i) => (
          <Box
            key={getUUID()}
            active={i === activeBox}
            onClick={() => setActiveBox(i)}
          >
            <Item>{b.location}</Item>
            <Item margin>{b.name}</Item>
            <Item>{b.street}</Item>
            <Item>{b.city}</Item>
            <ItemLink margin href={`mailto:${b.mail}`}>
              {b.mail}
            </ItemLink>
            {Array.isArray(b.phone) &&
              b.phone.map((p, i) => <Item key={i}>{p}</Item>)}
            {!Array.isArray(b.phone) && <Item>{b.phone}</Item>}
          </Box>
        ))}
      </StyledBoxes>
    </Container>
  );
};

export default Boxes;
