import { StyledGoals, Header, HeaderText } from "./Goals.Styled";
import headerBG from "../../../assets/image/etl-protax/goals/bg.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Topics from "./Topics/Topics";
import Values from "./Values/Values";
import Logo from "../../SubComponents/Logo/Logo";

const Goals = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledGoals>
      <Header bg={headerBG}>
        <Logo height="40px" dark={true} />
        <HeaderText>{translation.etlProtax.goals.headerText}</HeaderText>
      </Header>
      <Topics />
      <Values />
    </StyledGoals>
  );
};

export default Goals;
