import styled from "styled-components";

export const StyledIndicators = styled.div`
  position: absolute;
  bottom: -150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 20px;
  }
`;

export const Indicator = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.font.secondary};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  margin: 0 5px;
  cursor: pointer;
`;
