import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledBox = styled.div``;

export const Float = styled(Container)`
  background-color: rgba(208, 47, 38, 0.7);
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    transform: translateY(0px);
  }
`;

export const InnerFloat = styled.div`
  padding: 40px;
`;

export const Headline = styled.div`
  text-align: center;
  font-size: 30px;
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 32px;
`;

export const Split = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const Item = styled.div`
  width: 100%;
  padding: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
`;
