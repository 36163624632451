import { Hero } from "@think-internet/ui-components";
import Layout from "../Layout/Layout";
import {
  StyledCareer,
  HeroContentContainer,
  HeroContentWrapper,
  Subtitle,
  Title,
  By,
} from "./Career.Styled";
import heroImage from "../../assets/image/career/hero.png";
import Flag from "../SubComponents/Flag/Flag";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Boxes from "./Boxes/Boxes";
import Reasons from "./Reasons/Reasons";
import Banner from "./Banner/Banner";
import People from "./People/People";
import OpenPositions from "./OpenPositions/OpenPositions";
import { getUUID } from "../../utility";

const Career = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Layout authRequired={false}>
      <StyledCareer>
        <Hero img={heroImage}>
          <HeroContentContainer>
            <HeroContentWrapper>
              <Flag />
              <Subtitle>{translation.home.career.subtitle}</Subtitle>
              {translation.home.career.title.map((t) => (
                <Title key={getUUID()}>{t}</Title>
              ))}
              <By>{translation.home.career.by}</By>
            </HeroContentWrapper>
          </HeroContentContainer>
        </Hero>
        <Boxes />
        <Reasons />
        <Banner />
        <People />
        <OpenPositions />
      </StyledCareer>
    </Layout>
  );
};

export default Career;
