import styled from "styled-components";

export const StyledGoals = styled.div``;

export const Header = styled.div`
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  position: relative;
  flex-direction: column;
  gap: 15px;
`;

export const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  line-height: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: bold;
`;
