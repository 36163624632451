import Layout from "../Layout/Layout";
import { StyledLocations } from "./Locations.Styled";
import Box from "./Box/Box";
import Map from "./Map/Map";
import Boxes from "./Boxes/Boxes";
import Continents from "./Continents/Continents";
import { useState } from "react";

const Locations = () => {
  const [activeBox, setActiveBox] = useState(0);

  return (
    <Layout authRequired={false}>
      <StyledLocations>
        <Map activeIndex={activeBox} />
        <Boxes activeBox={activeBox} setActiveBox={setActiveBox} />
        <Box />
        <Continents />
      </StyledLocations>
    </Layout>
  );
};

export default Locations;
