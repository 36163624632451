import styled from "styled-components";
import flag from "../../../assets/image/flag.svg";

export const StyledSliderNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px;
`;

export const Element = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.color.font.secondary};
  display: flex;
  gap: 5px;
  cursor: pointer;
`;

export const Headline = styled.span`
  text-transform: uppercase;
  padding-left: 20px;
  ${({ isActive }) =>
    isActive &&
    `
    background-image: url(${flag});
    background-repeat: no-repeat;
    background-position: left;
    background-size: 30px;
    `}
`;
