import {
  Element,
  StyledSliderNavigation,
  Headline,
} from "./SliderNavigation.Styled";

const SliderNavigation = ({ elements, index, indexCallback }) => {
  return (
    <StyledSliderNavigation>
      {elements.map((element, i) => (
        <Element key={i} onClick={() => indexCallback(i)}>
          <Headline isActive={index === i}>{element.headline}</Headline>
        </Element>
      ))}
    </StyledSliderNavigation>
  );
};

export default SliderNavigation;
