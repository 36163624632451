import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Layout from "../Layout/Layout";
import {
  StyledContact,
  Bottom,
  Headline,
  Item,
  Split,
  NameType,
  Name,
  FormWrapper,
  Hero,
  Street,
  City,
  Mail,
  Phone,
  GenericInfo,
} from "./Contact.Styled";
import ContactForm from "./ContactForm/ContactForm";
import hero from "../../assets/image/contact/hero.jpg";
import { getUUID } from "../../utility";

const Contact = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authRequired={false}>
      <StyledContact>
        <Hero img={hero} />
        <FormWrapper>
          <ContactForm />
        </FormWrapper>
        <Bottom>
          <Container>
            <Headline>{translation.contact.headline}</Headline>
            <GenericInfo>
              <Mail href={`mailto:${translation.genericInfo.mail}`}>
                {translation.genericInfo.mail}
              </Mail>
              <Phone>{translation.genericInfo.phone}</Phone>
              <Phone>{translation.genericInfo.phone2}</Phone>
            </GenericInfo>
            <Split>
              {translation.contact.companies.map((c) => (
                <Item key={getUUID()}>
                  <Name>{c.name}</Name>
                  <NameType>{c.nameType}</NameType>
                  <Street>{c.street}</Street>
                  <City>{c.city}</City>
                </Item>
              ))}
            </Split>
          </Container>
        </Bottom>
      </StyledContact>
    </Layout>
  );
};

export default Contact;
