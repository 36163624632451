import styled from "styled-components";

export const StyledServices = styled.div``;

export const HeroContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeroContentWrapper = styled.div`
  text-align: center;
`;
export const Subtitle = styled.div`
  font-size: 25px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  margin-bottom: 15px;
`;
export const Title = styled.div`
  font-size: 70px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 60px;
  margin-top: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 30px;
    line-height: 25px;
  }
`;

export const SliderWrapper = styled.div`
  margin: 200px 0px;
`;
