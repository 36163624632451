import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { StyledBanner, TextWrapper, Text } from "./Banner.Styled";
import bgImage from "../../../assets/image/career/banner/bg.png";
import { Container } from "@think-internet/ui-components";

const Banner = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledBanner bg={bgImage}>
      <Container>
        <TextWrapper>
          <Text>{translation.career.banner.line1}</Text>
          <Text>{translation.career.banner.line2}</Text>
        </TextWrapper>
      </Container>
    </StyledBanner>
  );
};

export default Banner;
