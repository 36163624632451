import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const BoxesRelativeWrapper = styled(Container)`
  position: relative;
`;

export const StyledBoxes = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  position: absolute;
  top: -75px;
  width: calc(100% - 30px);
  transform: translateY(-10%);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    top: 0px;
    position: relative;
    width: 100%;
  }
`;
export const Box = styled.div`
  background-color: rgba(208, 47, 38, 0.7);
  padding: 40px 15px;
  width: calc(33.3% - 7.5px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  text-align: center;
  margin-bottom: 15px;
`;
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
`;
