import { Container, Hero } from "@think-internet/ui-components";
import Layout from "../Layout/Layout";
import {
  StyledDataProtection,
  HeroContentContainer,
  HeroContentWrapper,
  Title,
  Headline,
  P,
  Bold,
} from "./DataProtection.Styled";
import heroImage from "../../assets/image/dataProtection/hero.png";
import Flag from "../SubComponents/Flag/Flag";
import { useSelector } from "react-redux";
import props from "../../redux/props";

const DataProtection = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Layout authRequired={false}>
      <StyledDataProtection>
        <Hero img={heroImage}>
          <HeroContentContainer>
            <HeroContentWrapper>
              <Flag />
              <Title>{translation.dataProtection.headline}</Title>
            </HeroContentWrapper>
          </HeroContentContainer>
        </Hero>
        <Container>
          <Headline>Allgemeines</Headline>
          <P>
            Diese Datenschutzerklärung informiert gemäß § 13 Telemediengesetz
            (TMG) darüber, in welcher Weise, in welchem Umfang und zu welchen
            Zwecken personenbezogene Daten der Besucher (folgend „Sie“ oder
            „Nutzer“) der Website verarbeitet werden. Das unter Ziffer 1
            genannte Unternehmen (folgend „wir“) ist Diensteanbieter im Sinne
            des TMG und Verantwortlicher im Sinne des Datenschutzrechts.
          </P>
          <P>
            Die Datenverarbeitung auf dieser Webseite erfolgt auf Basis der
            Datenschutz-Grundverordnung (DS-GVO), des Bundesdatenschutzgesetzes
            (BDSG) und des TMG. Einzelfallabhängig können auch andere
            gesetzliche Rechtsgrundlagen maßgeblich sein. In diesem Sinne kann
            die Verarbeitung Ihrer personenbezogenen Daten auch auf der
            Grundlage Ihrer Einwilligung erfolgen.
          </P>
          <Headline>1. Name und Kontaktdaten des Verantwortlichen</Headline>
          <P>
            Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
          </P>
          <P>
            ETL Protax GmbH
            <br />
            Steuerberatungsgesellschaft
            <br />
            Magnolienweg 3
            <br />
            63741 Aschaffenburg
          </P>
          <P>
            Telefon: + 49 (0) 6021 44480
            <br />
            E-Mail:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:info@etl-protax.de"
            >
              info@etl-protax.de
            </a>
          </P>
          <Headline>
            2. Name und Kontaktdaten des betrieblichen Datenschutzbeauftragten
          </Headline>
          <P>
            Der/die betriebliche Datenschutzbeauftragte ist wie folgt
            erreichbar:
          </P>
          <P>
            ETL Datenservice GmbH
            <br />
            Widdersdorfer Str. 415
            <br />
            50933 Köln
          </P>
          <P>
            Telefon: (0221) 95440180
            <br />
            Telefax: (0221) 9544015
            <br />
            E-Mail:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:datenschutz@etl-datenservice.de"
            >
              datenschutz@etl-datenservice.de
            </a>
          </P>
          <Headline>3. Ihre Rechte als Betroffener</Headline>
          <P>Sie sind berechtigt,</P>
          <P>
            a) gemäß Artikel 15 DS-GVO Auskunft über Ihre von uns verarbeiteten
          </P>
          <P>personenbezogenen Daten zu verlangen.</P>
          <P>Insbesondere können Sie Auskunft über</P>
          <ul>
            <li>die Verarbeitungszwecke,</li>
            <li>die Kategorie der personenbezogenen Daten,</li>
            <li>
              die Kategorien von Empfängern, gegenüber denen Ihre Daten
              offengelegt wurden oder werden,
            </li>
            <li>die geplante Speicherdauer,</li>
            <li>
              das Bestehen eines Rechts auf Berichtigung, Löschung,
              Einschränkung der Verarbeitung oder Widerspruch,
            </li>
            <li>das Bestehen eines Beschwerderechts,</li>
            <li>
              die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben
              wurden, sowie über
            </li>
            <li>
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling und ggf. aussagekräftigen Informationen
              zu deren Einzelheiten verlangen.
            </li>
          </ul>
          <P>
            b) gemäß Artikel 16 DS-GVO unverzüglich die Berichtigung Sie
            betreffender unrichtiger Daten oder die Vervollständigung Ihrer bei
            uns gespeicherten personenbezogenen Daten zu verlangen.
          </P>
          <P>
            c) gemäß Artikel 17 DS-GVO die Löschung Ihrer bei uns gespeicherten
            personenbezogenen Daten zu verlangen. Dieses Recht besteht nicht,
            wenn die Verarbeitung
          </P>
          <ul>
            <li>
              zur Ausübung des Rechts auf freie Meinungsäußerung und
              Information,
            </li>
            <li>zur Erfüllung einer rechtlichen Verpflichtung,</li>
            <li>aus Gründen des öffentlichen Interesses oder</li>
            <li>
              zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen erforderlich ist.
            </li>
          </ul>
          <P>
            d) gemäß Artikel 18 DS-GVO die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen,
          </P>
          <ul>
            <li>soweit Sie die Richtigkeit dieser Daten bestreiten,</li>
            <li>
              falls die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
              ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese
              zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen benötigen oder Sie gemäß Artikel 21 DSGVO
              Widerspruch gegen die Verarbeitung eingelegt haben.
            </li>
          </ul>
          <P>
            e) gemäß Artikel 20 DS-GVO die Herausgabe der von Ihnen
            bereitgestellten personenbezogenen Daten in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten oder die
            Übermittlung an einen anderen Verantwortlichen zu verlangen.
          </P>
          <P>
            f) gemäß Artikel 7 Absatz 3 DS-GVO Ihre einmal erteilte Einwilligung
            uns gegenüber jederzeit zu widerrufen. Die Folge ist, dass wir die
            Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
            Zukunft nicht mehr fortführen dürfen.
          </P>
          <P>
            <Bold>4. Widerspruchsrecht</Bold>
          </P>
          <P>
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
            Interessen gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe f DS-GVO
            verarbeitet werden, sind Sie gemäß Artikel 21 DS-GVO berechtigt,
            Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
            einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
            besonderen Situation ergeben oder sich der Widerspruch gegen
            Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
            Widerspruchsrecht, das wir ohne Angabe einer besonderen Situation
            umsetzen.
          </P>
          <P>
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine E-Mail an&nbsp;
            <a target="_blank" rel="noreferrer" href="mailto:etl-berlin@etl.de">
              etl-berlin@etl.de
            </a>
            .
          </P>
          <P>
            <Bold>5. Beschwerderecht</Bold>
          </P>
          <P>
            Gemäß Artikel 77 DS-GVO haben Sie das Recht, sich bei einer
            Aufsichtsbehörde zu beschweren. Hierzu können Sie sich in der Regel
            an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
            Arbeitsplatzes oder unseres Firmensitzes wenden.
          </P>
          <P>
            <Bold>6. Verarbeitung personenbezogener Daten</Bold>
          </P>
          <ul>
            <li>
              „Verarbeitung“ im Sinne dieser Datenschutzerklärung bedeutet jeden
              – mit oder ohne Hilfe automatisierter Verfahren ausgeführten –
              Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
              personenbezogenen Daten. Dies ist z.B. das Erheben, das Erfassen,
              die Organisation, das Ordnen, die Speicherung, die Anpassung oder
              Veränderung, das Auslesen, das Abfragen, die Verwendung, die
              Offenlegung durch Übermittlung, Verbreitung oder eine andere Form
              der Bereitstellung. Der Abgleich oder die Verknüpfung, die
              Einschränkung, das Löschen oder die Vernichtung zählen ebenfalls
              dazu.
            </li>
            <li>
              „Personenbezogene Daten“ im Sinne dieser Datenschutzerklärung sind
              alle Informationen, die sich auf die persönlichen oder sachlichen
              Verhältnisse einer natürlichen Person beziehen und anhand derer
              diese direkt oder indirekt identifiziert werden kann. Dazu zählen
              z.B. Ihr Name, Ihre postalische Anschrift oder Ihre E-Mail Adresse
              oder Telefonnummer. Dasselbe gilt für Kreditkarten- und Kontodaten
              sowie andere Daten, die einen direkten oder indirekten Bezug zu
              Ihnen haben.
            </li>
          </ul>
          <P>
            Zu den personenbezogenen Daten im Sinne dieser Datenschutzerklärung
            zählen auch Informationen über den Besuch unserer Website. Dies
            können z.B. die IP-Adresse des anfragenden Rechners, Datum, Uhrzeit
            und Häufigkeit des Zugriffs, der geografische Ort von dem aus der
            Zugriff erfolgt, Name und URL der abgerufenen Datei oder eine andere
            Website sein, wenn der Zugriff von dieser aus erfolgt
            (Referrer-URL). Die Verarbeitung erfolgt in der Regel durch Cookies
            und / oder Logfiles (Siehe unten, Ziffer 9).
          </P>
          <P>
            <Bold>
              7. Erhebung und Speicherung personenbezogener Daten sowie Art und
              Zweck der Verwendung
            </Bold>
          </P>
          <P>
            <Bold>a) Datenspeicherung beim Besuch der Website</Bold>
          </P>
          <P>
            Wenn Sie unsere Website https://www.etl.de aufrufen, sendet der auf
            Ihrem Endgerät eingesetzte Browser automatisch Informationen an den
            Server unserer Website. Diese Informationen werden dort
            vorübergehend in einem sogenannten Logfile gespeichert und 7 Tage
            nach der Beendigung des Besuchs unserer Website automatisch
            gelöscht. Folgende Informationen werden ohne Ihr Zutun erfasst und
            bis zur automatisierten Löschung gespeichert:
          </P>
          <ul>
            <li>IP-Adresse des anfragenden Rechners,</li>
            <li>Datum und Uhrzeit des Zugriffs,</li>
            <li>Name und URL der abgerufenen Datei,</li>
            <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
            <li>verwendeter Browser,</li>
            <li>das Betriebssystem Ihres Rechners,</li>
            <li>Name Ihres Access-Providers (Internet-Dienstleisters),</li>
            <li>ungefährer Standort des Proxy-Servers,</li>
            <li>
              Status und übertragene Datenmenge im Rahmen des Besuchs unserer
              Websites.
            </li>
          </ul>
          <P>Wir verarbeiten die genannten Daten</P>
          <ul>
            <li>
              zur Gewährleistung eines reibungslosen Verbindungsaufbaus der
              Website,
            </li>
            <li>
              zur Gewährleistung einer komfortablen Nutzung unserer Website,
            </li>
            <li>zur Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zu weiteren administrativen Zwecken.</li>
          </ul>
          <P>
            Im Einzelfall kann die Verarbeitung auch zu anderen Zwecken
            erfolgen, sofern wir hierzu rechtlich verpflichtet sind, z. B. weil
            Strafverfolgungsbehörden uns hierzu verpflichten.
          </P>
          <P>
            Die Rechtsgrundlage für die Datenverarbeitung gemäß dieser
            Datenschutzerklärung ist Artikel 6 Absatz 1 DS-GVO. Unser
            berechtigtes Interesse folgt aus den oben aufgelisteten Zwecken zur
            Datenerhebung.
          </P>
          <P>
            Beim Besuch unserer Website können Cookies sowie Analysedienste zum
            Einsatz kommen, sofern Sie uns eine Einwilligung gegeben haben oder
            es sich um notwendige Session-Cookies handelt. Nähere Erläuterungen
            zu der Verwendung von Cookies und Analysediensten erhalten Sie unter
            den Ziffern 9 und 10 dieser Datenschutzerklärung.
          </P>
          <P>
            <Bold>b) Datenspeicherung bei der Newsletteranmeldung</Bold>
          </P>
          <P>
            Sofern Sie nach Artikel 6 Absatz 1 Satz 1 Buchstabe a DS-GVO
            ausdrücklich eingewilligt haben, wird Ihre E-Mail-Adresse dafür
            verwendet, Ihnen regelmäßig den Newsletter zu übersenden.
          </P>
          <P>
            Ihre Daten werden ausschließlich zur Personalisierung des
            Newsletters gespeichert. Eine Weitergabe an Dritte erfolgt nur im
            Rahmen der Versendung des Newsletters durch den externen
            Dienstleister CleverReach® GmbH &amp; Co. KG, Mühlenstraße 43, 26180
            Rastede. Um den Newsletter für Sie zu optimieren, werden
            Auswertungen vorgenommen, um zu messen, wie häufig der Newsletter
            geöffnet wird und auf welche Links die Leser klicken.
          </P>
          <P>
            Für den Empfang des Newsletters reicht die Angabe einer
            E-Mail-Adresse bei der Online-Anmeldung.
          </P>
          <P>
            Die Abmeldung ist jederzeit möglich. Die Abmeldung kann sowohl über
            einen Link am Ende eines jeden Newsletters oder auch jederzeit per
            E-Mail an&nbsp;
            <a target="_blank" rel="noreferrer" href="mailto:newsletter@etl.de">
              newsletter@etl.de
            </a>
            &nbsp;erfolgen.
          </P>
          <P>
            <Bold>c) Nutzung unseres Kontaktformulars</Bold>
          </P>
          <P>
            Bei Fragen jeglicher Art können Sie uns über ein ggf. auf der
            Website bereitgestelltes Formular oder per E-Mail kontaktieren. Die
            Angabe einer gültigen E-Mail-Adresse ist erforderlich, damit wir
            wissen, von wem die Anfrage stammt und um diese beantworten zu
            können. Weitere Angaben können freiwillig getätigt werden.
          </P>
          <P>
            Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
            nach Artikel 6 Absatz 1 Satz 1 Buchstabe a DS-GVO auf Grundlage
            Ihrer freiwillig erteilten Einwilligung.
          </P>
          <P>
            Die personenbezogenen Daten, die wir für die Benutzung des
            Kontaktformulars erheben, werden nach Erledigung der von Ihnen
            gestellten Anfrage automatisch gelöscht.
          </P>
          <P>
            <Bold>d) Nutzung Ihres E-Mail-Programms</Bold>
          </P>
          <P>
            Wenn Sie über Ihr E-Mail-Programm mit uns in Kontakt treten, kann
            nicht ausgeschlossen werden, dass Dritte den Inhalt der Mail auf dem
            Übertragungsweg zur Kenntnis nehmen oder verfälschen.
          </P>
          <P>
            Nachrichten mit schutzwürdigen Inhalten sollten Sie daher
            grundsätzlich nur über unser Kontaktformular oder in verschlüsselter
            Form an uns senden.
          </P>
          <P>
            Zur Verschlüsselung benötigen Sie ein geeignetes
            Verschlüsselungswerkzeug, das mit dem OpenPGP Standard&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.ietf.org/rfc/rfc4880.txt"
            >
              RFC4880
            </a>
            &nbsp;kompatibel ist.
          </P>
          <P>
            <Bold>e) Registrierung für den Download von Dokumenten</Bold>
          </P>
          <P>
            Wir speichern personenbezogene Daten von Besuchern der Website, die
            Informationen oder Angebote zu Produkten und Dienstleistungen
            anfordern, die sich auf unseren Webseiten für den Download von
            Unterlagen registriert haben. Diese Formulare auf unseren Webseiten
            sind mit der Pardot Marketing Automation verknüpft (mehr dazu
            erfahren Sie unter Ziffer 9). Freiwillig angegebene und übermittelte
            personenbezogene Daten werden zunächst in Pardot gespeichert, um
            dann mit dem Salesforce CRM-System (Custom Relationship
            Management/Kundenbeziehungsmanagement) für den Zweck der
            Kontaktaufnahme und/ oder der Zusendung von Informationen bearbeitet
            zu werden. Salesforce speichert keine IP-Adressen, sondern verwendet
            die individuellen Zuordnungsmerkmale „unique visitor ID“ und „unique
            identifier“. Personenbezogene Rückschlüsse sind dadurch nicht
            möglich. Unter folgendem Link finden Sie detaillierte Information,
            wie Salesforce Ihre Daten während des Besuchs von Webseiten
            verarbeitet:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.salesforce.com/de/company/privacy/full_privacy/"
            >
              https://www.salesforce.com/de/company/privacy/full_privacy/
            </a>
          </P>
          <P>
            Mittels Pardot gesendete E-Mails verwenden Tracking-Technologien.
            Die über das Tracking ermittelten Daten verwenden wir, um
            herauszufinden, für welche Themen Sie sich interessieren. Das
            Tracking erfasst, ob Sie unsere E-Mails geöffnet haben und auf
            welche Links Sie klicken. Diese Informationen nutzen wir zur
            Optimierung unserer E-Mails sowie der von uns bereitgestellten
            Services. Das Tracking durch Pardot können Sie durch Ihre
            Browsereinstellungen sicherstellen. Nähere Angaben zum Tracking
            durch Pardot finden Sie unter Ziffer 9.
          </P>
          <P>
            <Bold>f) Audio- und Videokonferenzen</Bold>
          </P>
          <P>
            Für die Kommunikation und Meetings setzen wir unter anderem
            Online-Konferenztools ein. Die einzelnen Konferenztools, die wir
            nutzen, sind weiter unten aufgelistet. Wenn Sie mit uns per Video-
            oder Audiokonferenz über Internet kommunizieren, werden Ihre
            personenbezogenen Daten von uns und dem Anbieter des Konferenztools
            erfasst und verarbeitet. Der Umfang der Daten hängt dabei auch davon
            ab, welche Angaben zu Daten Sie vor bzw. bei der Teilnahme machen.
            Folgende personenbezogene Daten sind Gegenstand der Verarbeitung:
          </P>
          <ul>
            <li>
              Angaben zum Benutzer: Vorname, Nachname, Telefon (optional).
              E-Mail-Adresse, Passwort(wenn ,,Single-Sign-On“ nicht verwendet
              wird), Profilbild (optional), Abteilung (optional)
            </li>

            <li>
              Meeting-Metadaten: Thema, Beschreibung (optional),
              Teilnehmer-lP-Adressen, Geräte-/Hardware-lnformationen
            </li>

            <li>
              Bei Aufzeichnungen (optional): MP4-Datei aller Video-, Audio- und
              Präsentationsaufnahmen, M4A-Datei aller Audioaufnahmen, Textdatei
              des Online-Meeting-Chats.
            </li>

            <li>
              Bei Einwahl mit dem Telefon: Angabe zur eingehenden und
              ausgehenden Rufnummer, Ländername, Start- und Endzeit. Ggf. können
              weitere Verbindungsdaten wie z.B. die IP-Adresse des Geräts
              gespeichert werden.
            </li>

            <li>
              Text-, Audio- und Videodaten: Sie haben ggf. die Möglichkeit, in
              einer Online-Konferenz die Chat-, Fragen- oder Umfragenfunktionen
              zu nutzen. Insoweit werden die von Ihnen gemachten Texteingaben
              verarbeitet, um diese in der Online-Konferenz anzuzeigen und ggf.
              zu protokollieren. Um die Anzeige von Video und die Wiedergabe von
              Audio zu ermöglichen, werden entsprechend während der Dauer des
              Meetings die Daten vom Mikrofon lhres Endgeräts sowie von einer
              etwaigen Videokamera des Endgeräts verarbeitet. Sie können die
              Kamera oder das Mikrofon jederzeit selbst abschalten bzw.
              stummstellen.
            </li>
          </ul>
          <P>
            Um an einer Online-Konferenz teilzunehmen bzw. den „Meeting-Raum“ zu
            betreten, müssen Sie zumindest Angaben zu Ihrem Namen machen. Dieser
            kann in den Einstellungen pseudonymisiert werden
          </P>
          <P>
            Weiter Hinweise zur Datenverarbeitung durch die eingesetzten
            Konferenztools entnehmen Sie bitte den Datenschutzerklärungen der
            jeweils eingesetzten Tools, die wir unten im Text verlinkt haben.
          </P>
          <P>Zweck der Verarbeitung und Rechtsgrundlagen</P>
          <P>
            Wir nutzen die Konferenztools, um Telefonkonferenzen.
            Online-Meetings, Videokonferenzen und/oder auch Webinare
            durchzuführen und gegenüber unseren Mandanten anzubieten (A(. 6 Abs.
            I lit. b) DSGVO). Darüber hinaus dient der Einsatz der
            Konferenztools der allgemeinen
          </P>
          <P>
            Vereinfachung und Beschleunigung der Kommunikation mit uns
            (berechtigtes Interesse gem. Art. 6 Abs. 1 lit 0 DSGVO). soweit eine
            Einwilligung von Ihnen abgefragt wurde, erfolgt der Einsatz der
            Konferenztools auf Grundlage der von Ihnen erteilten Einwilligung;
            die Einwilligung ist jederzeit mit Wirkung für die Zukunft
            widerrufbar.
          </P>
          <P>Löschung von Daten und Speicherdauer</P>
          <P>
            Wir löschen personenbezogene Daten grundsätzlich dann, wenn kein
            Erfordernis für eine weitere Speicherung besteht. Ein Erfordernis
            kann insbesondere dann bestehen, wenn die Daten noch benötigt
            werden, um vertragliche Leistungen zu erfüllen, Gewährleistungs- und
            ggf. Garantieansprüche zu prüfen und zu gewähren oder abwehren zu
            können. Im Falle von gesetzlichen Aufbewahrungspflichten kommt eine
            Löschung erst nach Ablauf der jeweiligen
          </P>
          <P>
            Aufbewahrungspflicht in Betracht. Auf die Speicherdauer Ihrer Daten,
            die von den Betreibern oder Konferenztools zu eigenen Zwecken
            gespeichert werden, haben wir keinen Einfluss. Für weitere
            Einzelheiten hierzu können Sie sich direkt bei den Betreibern der
            Konferenztools informieren.
          </P>
          <P>Eingesetzte Konferenztool</P>
          <P>Wir setzen folgende Konferenztools ein</P>
          <P>GoToMeeting:</P>
          <P>
            Wir nutzen Microsoft Teams. Anbieter ist die LogMeIn, lnc., 320
            Summer Street Boston, MA 02210, USA. Einzelne Details zur
            Datenverarbeitung entnehmen Sie bitte der Datenschutzerklärung von
            MS Teams unter{" "}
            <a
              href="https://learn.microsoft.com/de-de/microsoftteams/teams-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://learn.microsoft.com/de-de/microsoftteams/teams-privacy
            </a>
          </P>
          <P>
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details dazu
            finden Sie hier{" "}
            <a
              href="https://logmeincdn.azureedge.net/legal/lmi-customer-dpa-2020v1-de.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://logmeincdn.azureedge.net/legal/lmi-customer-dpa-2020v1-de.pdf
            </a>
          </P>

          <P>
            Es wurden mit den Anbietern der Konferenztools
            Auftragsverarbeitungsvereinbarungen getroffen.
          </P>
          <P>
            <Bold>Alternative Hubspot:</Bold>
          </P>
          <P>
            <Bold>Newsletter, Mailings, Downloads mit Hubspot</Bold>
          </P>
          <P>
            Auf den Webseiten stellen wir auf der Basis einer Einwilligung des
            Nutzers gemäß Art. 6 Abs. 1a EU DS-GVO, ggf. i.V. mit § 7 Abs. 2 Nr.
            3 UWG ein breites Angebot an Newslettern, Mailings und Downloads zur
            Verfügung. Auch auf Grundlage einer gesetzlichen Erlaubnis nach § 7
            Abs. 3 UWG können wir Betroffenen ggf. bestimmte Informationen per
            E-Mail übersenden.
          </P>
          <P>
            Für eine Anmeldung zu themenbezogenen Newslettern und Mailings sowie
            dem Download bestimmter Dokumente (z.B. Studien) von ETL sind die
            Angabe des Namens und der E-Mail-Adresse erforderlich. Mit der
            Anmeldung bzw. dem Download wird ETL auch das Einverständnis
            erteilt, künftige Besuche eines Nutzers auf unseren Webseiten
            personenbezogen zu erfassen, um diesem zielgerichtet und persönlich
            auf die jeweiligen Interessen zugeschnittene themenbezogene
            Informationen (z.B. aktuelle Studien, Umfragen) zukommen lassen zu
            können. Hierbei erfassen wir durch einen Cookie unseres
            Dienstleisters HubSpot die einzelnen ETL-Webseiten und Themen, die
            sich ein registrierter Nutzer bei seinen Besuchen ansieht (siehe
            auch unten Ziff. 3f (8)).
          </P>
          <P>
            HubSpot Inc., 25 First Street, Cambridge, MA 02141 USA, ist ein
            Dienstleister, der eine All-in-one-Plattforn für Inbound-Marketing,
            Sales, CRM und Kundenservice anbietet. Nach der Anmeldung für
            Newsletter, Mailings oder Downloads auf den ETL-Webseiten erhält
            jeder Nutzer eine Bestätigungs-E-Mail an die angegebene
            E-Mail-Adresse (sog. Double-Opt-In-Verfahren). Erst nach Betätigung
            des in dieser E-Mail enthaltenen Links ist die Anmeldung
            abgeschlossen.
          </P>
          <P>
            Einmal erteilte Einwilligungen zum Empfang von Newslettern, Mailings
            oder Downloads können jederzeit über einen Link am Ende jeder E-Mail
            oder durch eine Nachricht an das ETL-Postfach&nbsp;
            <a target="_blank" rel="noreferrer" href="mailto:newsletter@etl.de">
              newsletter@etl.de
            </a>
            &nbsp;widerrufen werden.
          </P>
          <P>
            Anmeldungen für Newsletter, Mailings oder Downloads werden auf der
            Basis unseres berechtigten Interesses, die Anmeldung und
            Einwilligung eines Nutzers jederzeit nachweisen zu können,
            protokolliert (Art. 6 Abs. 1 lit. f EU DS-GVO).
          </P>
          <P>
            Sollten Sie unsere Angebote während eines Jahres in keiner Form
            genutzt haben, gelten Sie als nicht interessiert und werden
            automatisch aus HubSpot gelöscht.
          </P>
          <P>
            <Bold>8. Weitergabe von Daten</Bold>
          </P>
          <P>Wir geben Ihre persönlichen Daten nur weiter, wenn</P>
          <P>
            a) Sie gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a DS-GVO Ihre
            ausdrückliche Einwilligung dazu erteilt haben.
          </P>
          <P>
            b) die Weitergabe nach Artikel 6 Absatz 1 Satz 1 Buchstabe f DS-GVO
            zur Geltendmachung, zur Ausübung oder zur Verteidigung von
            Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
            besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der
            Nichtweitergabe Ihrer Daten haben.
          </P>
          <P>
            c) für die Weitergabe nach Artikel 6 Absatz 1 Satz 1 Buchstabe c
            DS-GVO eine gesetzliche Verpflichtung besteht.
          </P>
          <P>
            d) dies gesetzlich zulässig und nach Artikel 6 Absatz 1 Satz 1
            Buchstabe b DS-GVO für die Abwicklung von Vertragsverhältnissen mit
            Ihnen erforderlich ist.
          </P>
          <P>
            <Bold>9. Cookies</Bold>
          </P>
          <P>Wir setzen auf unserer Website Cookies ein.</P>
          <P>
            <Bold>Was sind Cookies?</Bold>
          </P>
          <P>
            Cookies sind kleine Dateien, die Ihr Browser automatisch erstellt
            und auf Ihrem Endgerät (Laptop, Tablet, Smartphone o. ä.) speichert,
            wenn Sie unsere Website besuchen. Cookies richten auf Ihrem Endgerät
            keinen Schaden an. Insbesondere enthalten sie weder Viren, Trojaner
            noch sonstige Schadsoftware.
          </P>
          <P>
            In dem Cookie werden Informationen abgelegt, die sich jeweils im
            Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Zu
            diesen Informationen gehören z. B. Ihre bevorzugte Sprache, Ihre
            bevorzugten Aktivitäten auf der Website oder Informationen über das
            eingesetzte Endgerät. Aufgrund dieser Informationen kann unser
            System das Endgerät sofort erkennen und die Informationen gemäß den
            von Ihnen getroffenen Voreinstellungen direkt verfügbar machen.
            Unmittelbare Kenntnis von Ihrer Identität erhalten wir dadurch
            nicht.
          </P>
          <P>
            <Bold>Wie verwalte ich die Cookies?</Bold>
          </P>
          <P>
            Prüfen Sie die Arten von Funktionen und Cookies, die diese Website
            nutzt, sowie Ihre individuellen Einstellungen. Bitte beachten Sie,
            dass erforderliche Cookies benötigt werden, damit die Seite
            funktioniert. Diese können nicht deaktiviert werden.
          </P>
          <P>
            <Bold>Speicherdauer</Bold>
          </P>
          <P>
            Notwendige Cookies werden automatisch gelöscht, wenn Sie die Website
            wieder verlassen.
          </P>
          <P>
            Für Ihre im Übrigen getroffenen Einstellungen legen wir ein für
            diese Domain gültiges Cookie mit den getroffenen Einstellungen mit
            einer Laufzeit von einem Jahr in Ihrem Browser ab. Dieses können Sie
            jederzeit hier&nbsp;löschen.
          </P>
          <P>
            <Bold>Welche Cookies nutzen wir?</Bold>
          </P>
          <table width="0">
            <tbody>
              <tr>
                <td>Cookie-Name</td>
                <td>Speicherdauer</td>
                <td>Domäne</td>
                <td>Cookie-Zweck</td>
              </tr>
              <tr>
                <td>cookieSettings</td>
                <td>1 Jahr</td>
                <td>.etl.de</td>
                <td>Speichert Ihre Cookie-Entscheidung</td>
              </tr>
            </tbody>
          </table>
          <P>
            Im Falle einer Löschung dieses Cookies werden wir Sie beim nächsten
            Besuch unserer Website erneut um Ihre Zustimmung zur Cookie-Nutzung
            bitten.
          </P>
          <P>
            <Bold>a) Session Cookies</Bold>
          </P>
          <P>
            Wir nutzen Session-Cookies dazu, um die Nutzung unseres Angebots für
            Sie angenehmer zu gestalten. So nutzen wir diese, um zu erkennen, ob
            Sie einzelne Seiten unserer Website bereits im Rahmen der aktuellen
            Sitzung besucht haben. Die Session-Cookies werden unmittelbar nach
            Verlassen unserer Website automatisch gelöscht. Der Einsatz von
            Session-Cookies ist für die Funktion der Website unbedingt
            erforderlich. Er erfolgt deshalb auf der rechtlichen Basis von
            Artikel 6 Abs. 1 Satz 1 Buchstabe f DS-GVO.
          </P>
          <P>
            <Bold>Welche Cookies nutzen wir?</Bold>
          </P>
          <table width="0">
            <tbody>
              <tr>
                <td>
                  <Bold>Cookie-Name</Bold>
                </td>
                <td>
                  <Bold>Speicherdauer</Bold>
                </td>
                <td>
                  <Bold>Domäne</Bold>
                </td>
                <td>
                  <Bold>Cookie- Zweck</Bold>
                </td>
              </tr>
              <tr>
                <td>PHPSESSID</td>
                <td>Browsersitzung</td>
                <td>www.etl.de</td>
                <td>Speichert vorübergehend Sitzungsdaten</td>
              </tr>
            </tbody>
          </table>
          <P>
            <Bold>b) Betreiber-Cookies</Bold>
          </P>
          <P>
            Wir setzen auf unserer Website Betreiber-Cookies ein, sofern Sie
            hierin eingewilligt haben. Diese Cookies sind erforderlich, um z.B.
            den Kartendienst von Google-Maps zu nutzen, mit dem Sie sich
            Standorte unserer Kanzleien anzeigen lassen können.
          </P>
          <P>
            Die meisten Browser akzeptieren Cookies automatisch. Sie können
            Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem
            Computer gespeichert werden oder stets ein Hinweis erscheint, bevor
            ein neuer Cookie angelegt wird.
          </P>
          <P>
            Sollten Sie wünschen, dass nur Cookies von uns, nicht aber von
            dritten, mit uns kooperierenden Unternehmen auf Ihrem Endgerät
            gespeichert werden, können Sie dies verhindern, indem Sie in Ihrem
            Browser die Option „Cookies von Drittanbietern blockieren“
            aktivieren.
          </P>
          <P>
            Bitte beachten Sie, dass die Deaktivierung von Cookies dazu führen
            kann, dass Sie nicht alle Funktionen unserer Website nutzen können.
            Sofern Sie z.B. die Kartenfunktion nutzen wollen, ist eine
            Einwilligung in das Setzen von Betreiber-Cookies erforderlich.
            Ebenso ist es dafür erforderlich, dass Sie Ihrem Browser gestatten,
            Cookies von Drittanbietern zuzulassen.
          </P>
          <P>
            <Bold>c) Tracking- und Targeting-Cookies</Bold>
          </P>
          <P>
            Wir setzen auf unserer Website Tracking- und Targeting-Cookies ein,
            sofern Sie hierin eingewilligt haben. Diese Cookies sind
            erforderlich, um unsere Website auf Ihre Bedürfnisse hin zu
            optimieren. Hierzu gehört eine bedarfsgerechte Gestaltung und
            fortlaufende Verbesserung unseres Angebotes einschließlich der
            Verknüpfung zu unseren Social-Media-Angeboten von z.B. Facebook,
            Twitter und XING.
          </P>
          <P>
            Wir sind bestrebt, eine bedarfsgerechte Gestaltung und die
            fortlaufende Optimierung unserer Website sicherzustellen. Auf
            Grundlage von Artikel 6 Absatz 1 Satz 1 Buchstabe a DS-GVO, setzen
            wir daher die folgend aufgeführten Tracking-Maßnahmen ein, sofern
            Sie hierin eingewilligt haben. Wir nutzen die Tracking-Maßnahmen
            auch, um die Nutzung unserer Website statistisch zu erfassen und zum
            Zwecke der Optimierung unseres Angebotes.
          </P>
          <P>
            Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind den
            Hinweisen zu den entsprechenden Tracking-Tools zu entnehmen.
          </P>
          <ul>
            <li>
              <Bold>Google Analytics</Bold>
            </li>
          </ul>
          <P>
            Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
            Optimierung unserer Seiten nutzen wir Google Analytics, einen
            Webanalysedienst der&nbsp;<Bold>Google Inc</Bold>.&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.de/intl/de/about/"
            >
              (https://www.google.de/intl/de/about/)
            </a>
            &nbsp;(Google Ireland Limited, Gordon House, Barrow Street, Dublin
            4, Irland; im Folgenden „Google“). In diesem Zusammenhang werden
            pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter
            Ziffer 9) verwendet. Der Einsatz von Google Analytics erfolgt im
            Rahmen der Auftragsverarbeitung gemäß Artikel 28 DS-GVO.
          </P>
          <P>
            Die durch den Cookie erzeugten Informationen über Ihre Benutzung
            dieser Website wie
          </P>
          <ul>
            <li>Browser-Typ/-Version,</li>
            <li>verwendetes Betriebssystem,</li>
            <li>Referrer-URL (die zuvor besuchte Seite),</li>
            <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
            <li>Uhrzeit der Serveranfrage,</li>
          </ul>
          <P>
            werden an einen Server von Google übertragen und dort gespeichert.
            Die Informationen werden verwendet, um die Nutzung der Website
            auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere Dienstleistungen, die mit der
            Websitenutzung und der Internetnutzung im Zusammenhang stehen, zu
            Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser
            Internetseiten zu erbringen. Auch diese Informationen werden
            gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
            vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag
            verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen
            Daten von Google zusammengeführt, soweit wir dies beeinflussen
            können. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung
            nicht möglich ist (IP-Masking).
          </P>
          <P>
            Sie können die Installation der Cookies durch eine entsprechende
            Einstellung der Browser-Software verhindern; wir weisen jedoch
            darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich genutzt werden können.
          </P>
          <P>
            Sie können darüber hinaus die Erfassung der durch das Cookie
            erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
            Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google
            verhindern, indem Sie ein&nbsp;
            <Bold>Browser-Add-on herunterladen und installieren</Bold>&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
            >
              (https://tools.google.com/dlpage/gaoptout?hl=de)
            </a>
            .
          </P>
          <P>
            Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen
            Endgeräten, können Sie die Erfassung durch Google Analytics zudem
            verhindern, indem Sie auf den folgenden Link klicken:&nbsp;Google
            Analytics deaktivieren.
          </P>
          <P>
            Beim Klicken auf diesen Link wird ein Opt-out-Cookie gesetzt, das
            die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website
            verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur
            für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie
            die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut
            setzen.
          </P>
          <ul>
            <li>
              <Bold>Google Tag Manager</Bold>
            </li>
          </ul>
          <P>
            Mit dem Google Tag Manager können Vermarkter Website-Tags über eine
            Oberfläche verwalten. Der Tag Manager selbst, der die Tags einsetzt,
            funktioniert jedoch ohne Cookies und erfasst keine personenbezogenen
            Daten. Der Tag Manager sorgt lediglich für die Auslösung anderer
            Tags, die ihrerseits unter Umständen Daten erfassen. Zu diesen
            jeweiligen Drittanbietern finden sich entsprechende Erklärungen in
            dieser Datenschutzerklärung. Der Google Tag Manager verwendet diese
            Daten aber nicht. Haben Sie eine Deaktivierung von Cookies
            eingestellt oder sonst vorgenommen, wird diese für alle
            Tracking-Tags beachtet, die mit dem Google Tag Manager eingesetzt
            wurden, das Tool ändert also Ihre Cookie-Einstellungen nicht.
          </P>
          <P>
            Möglicherweise bittet Google Sie um die Erlaubnis, einige
            Produktdaten (z. B. Ihre Kontoinformationen) an andere
            Google-Produkte weiterzugeben, um bestimmte Funktionen zu
            aktivieren, z. B. die Hinzufügung neuer Conversion-Tracking-Tags für
            AdWords zu vereinfachen. Außerdem überprüfen die Entwickler von
            Google von Zeit zu Zeit Informationen zur Produktnutzung, um das
            Produkt weiter zu optimieren. Google wird jedoch niemals Daten
            dieser Art ohne Ihre Zustimmung an andere Google-Produkte
            weitergeben.
          </P>
          <P>
            Weitere Informationen finden Sie in den Nutzungsrichtlinien (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/intl/de/tagmanager/use-policy.html"
            >
              https://www.google.com/intl/de/tagmanager/use-policy.html
            </a>
            ) von Google und den Datenschutzhinweisen (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/intl/de/tagmanager/faq.html"
            >
              https://www.google.com/intl/de/tagmanager/faq.html
            </a>
            ) von Google für dieses Produkt.
          </P>
          <P>
            <Bold>Welche Cookies nutzen wir?</Bold>
          </P>
          <table width="0">
            <tbody>
              <tr>
                <td>Cookie-Name</td>
                <td>Speicherdauer</td>
                <td>Domäne</td>
                <td>Cookie-Zweck</td>
              </tr>
              <tr>
                <td>_ga</td>
                <td>2 Jahre</td>
                <td>.etl.de</td>
                <td>Anonymisierte eindeutige Benutzerzuordnung</td>
              </tr>
              <tr>
                <td>_gid</td>
                <td>24 Stunden</td>
                <td>.etl.de</td>
                <td>Anonymisierte eindeutige Benutzerzuordnung</td>
              </tr>
              <tr>
                <td>_gat</td>
                <td>1 Minute</td>
                <td>.etl.de</td>
                <td>
                  Steuert die Begrenzung der Zugriffsrate für Google Analytics
                </td>
              </tr>
              <tr>
                <td>_gat_&lt;PROPERTY-ID&gt;</td>
                <td>1 Minute</td>
                <td>.etl.de</td>
                <td>
                  Steuert die Begrenzung der Zugriffsrate für Google Analytics
                </td>
              </tr>
              <tr>
                <td>_gat_gtag_&lt;PROPERTY-ID&gt;</td>
                <td>1 Minute</td>
                <td>.etl.de</td>
                <td>
                  Steuert die Begrenzung der Zugriffsrate für Google Tag Manager
                </td>
              </tr>
              <tr>
                <td>_ga-disable-&lt;PROPERTY-ID&gt;</td>
                <td>bis 31.12.2099</td>
                <td>.etl.de</td>
                <td>
                  Opt-Out – verhindert, wenn gesetzt, die weitere Erfassung
                  durch Google Analytics
                </td>
              </tr>
            </tbody>
          </table>
          <P>
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google
            Analytics finden Sie etwa in der&nbsp;
            <Bold>Google Analytics-Hilfe</Bold>&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.google.com/analytics/answer/6004245?hl=de"
            >
              (https://support.google.com/analytics/answer/6004245?hl=de)
            </a>
            .
          </P>
          <ul>
            <li>
              <Bold>Webseiten-Analyse mit Pardot</Bold>
            </li>
          </ul>
          <P>
            Unsere Webseiten verwendet das Pardot Marketing Automation System
            („Pardot MAS“) von Pardot LLC, 950 East Paces Ferry Road, Suite 3300
            Atlanta, GA 30326, USA. Pardot MAS ist eine spezielle Software der
            salesforce.com Inc zur Erfassung und Auswertung der Bewegungsprofile
            von Website-Besuchern. Durch Pardot Services werden maximal zwei
            Cookies gesetzt. Dabei handelt es sich um einen „Visitor Cookie“ und
            um einen „Pardot App Session Cookie“. Über den „Visitor Cookie“ wird
            eine Identifikationsnummer generiert, anhand derer der Browser des
            Webseiten-Besuchers wiedererkannt wird. Bei der
            Identifikationsnummer handelt es sich um einen generierten
            Zahlencode, der außerhalb von Pardot Services keinerlei Bedeutung
            hat. Der „Pardot App Session Cookie“ wird nur dann gesetzt, wenn
            sich ein Kunde in der Pardot App als Nutzer einloggt. Alle Cookies
            erhalten lediglich den generierten Nummerncode.
          </P>
          <P>
            Ihre Zustimmung ist freiwillig. Sollten Sie das Setzen von Cookies
            ablehnen, kann dies zu Einschränkungen gewisser Funktionen sowie der
            Nutzerfreundlichkeit führen. Dem Webseitenbesucher wird nur dann
            eine Nutzerkennung zugewiesen, wenn er bei erstmaligen Seitenaufruf
            dem Cookie-Hinweis zustimmt. Indem Sie zustimmen, erklären Sie sich
            mit dem Einsatz und der Funktionalität von Pardot MAS einverstanden.
            Sie können Ihre Einwilligung jederzeit mit der Wirkung für die
            Zukunft widerrufen. Wenden Sie sich hierfür an die in dieser
            Datenschutzerklärung bereitgestellten Kontaktdaten. Sie können der
            pseudonymen Nutzererkennung auch widersprechen, indem Sie Ihre
            Browsereinstellungen ändern und unsere Marketing- und
            Trackingcookies deaktivieren.
          </P>
          <P>
            Ist dem Webseitenbesucher einmal eine ID zugeordnet, kann er bei
            einem erneuten Seitenaufruf durch ein „Einmal-Cookie“
            (Session-Cookie) wiedererkannt werden. Dies erlaubt uns dem
            Webseitenbesucher ein nach seinen Interessen zugeschnittenes
            Produktangebot zukommen zu lassen, sollte er sich für einen
            Newsletter anmelden oder für den Download von Dokumenten
            registrieren. Zu diesem Zweck können die auf diesem Weg gesammelten
            Anmeldedaten Daten mit dem Nutzungsprofil und Klickpfad
            zusammengeführt werden. Dies setzt ebenfalls Ihre aktive Zustimmung
            bei der Newsletter-Anmeldung voraus.
          </P>
          <P>
            Wir nutzen Pardot, um die Nutzung unserer Webseite analysieren und
            regelmäßig verbessern zu können. Dazu analysieren wir das Nutzer-
            bzw. Klickverhalten auf unserer Webseite, um unsere Kommunikation
            besser auf Kundenbedürfnisse zuschneiden zu können.
          </P>
          <P>&nbsp;</P>
          <P>
            <Bold>Welche Cookies nutzen wir?</Bold>
          </P>
          <table width="0">
            <tbody>
              <tr>
                <td>Cookie-Name</td>
                <td>Speicherdauer</td>
                <td>Domäne</td>
                <td>Cookie-Zweck</td>
              </tr>
              <tr>
                <td>pi_opt_in&lt;ACCOUNT-ID&gt;</td>
                <td>10 Jahre</td>
                <td>jeweilige Aktionsseite</td>
                <td>
                  Wenn „Anmeldeeinstellungen für Verfolgung“ aktivierbar ist und
                  deaktiviert/aktiviert wurde, wird die Entscheidung darin
                  gespeichert.
                </td>
              </tr>
              <tr>
                <td>visitor_id&lt;ACCOUNT-ID&gt;</td>
                <td>10 Jahre</td>
                <td>jeweilige Aktionsseite</td>
                <td>
                  Das Besucher-Cookie besteht aus einer eindeutigen Besucher-ID
                  und dem eindeutigen Bezeichner Ihres Accounts.
                </td>
              </tr>
              <tr>
                <td>visitor_id&lt;ACCOUNT-ID&gt;-hash</td>
                <td>10 Jahre</td>
                <td>jeweilige Aktionsseite</td>
                <td>
                  Das Hash-Cookie eines Besuchers enthält die Account-ID und
                  dient zum Speichern eines eindeutigen Hash-Werts.
                </td>
              </tr>
              <tr>
                <td>lpv&lt;ACCOUNT-ID&gt;</td>
                <td>30 Minuten</td>
                <td>jeweilige Aktionsseite</td>
                <td>
                  Dieses LPV-Cookie wird gesetzt, um Pardot davon abzuhalten,
                  mehrere Seitenaufrufe für ein einzelnes Objekt während einer
                  30-minütigen Sitzung zu verfolgen.
                </td>
              </tr>
              <tr>
                <td>pardot</td>
                <td>jeweilige Sitzungsdauer</td>
                <td>jeweilige Aktionsseite</td>
                <td>
                  Das Cookie kennzeichnet eine aktive Sitzung und wird nicht zur
                  Verfolgung verwendet.
                </td>
              </tr>
            </tbody>
          </table>
          <P>
            Rechtsgrundlage für die Nutzung von Pardot ist Art. 6 Abs. 1 S. 1
            lit. f DSGVO. Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details zum
            Umgang von Pardot mit personenbezogenen Daten finden Sie hier:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/EU-Data-Transfer-Mechanisms-FAQ.pdf"
            >
              https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/EU-Data-Transfer-Mechanisms-FAQ.pdf
            </a>
          </P>
          <P>&nbsp;</P>
          <ul>
            <li>
              <Bold>Google Ads (ehemals Google AdWords)</Bold>
            </li>
          </ul>
          <P>
            Wir verwenden auf unserer Website das Online-Werbeprogramm „Google
            AdWords“ und in diesem Rahmen Conversion-Tracking
            (Besuchsaktionsauswertung) sowie Google Dynamic Remarketing
            (zielgruppenspezifische Werbung). Das Google Ads ist ein Dienst von
            Google (Google Ireland Ltd., Google Building Gordon House, 4 Barrow
            St, Dublin, D04 E5W5, Irland (“Google“)).
          </P>
          <P>
            Google Ads Conversion nutzen wir, um mit Hilfe von Werbemitteln
            (sogenannten Google Ads) auf externen Webseiten auf unsere
            attraktiven Angebote aufmerksam zu machen. Hierin liegt auch unser
            berechtigtes Interesse. Die erforderlichen Verarbeitungen erfolgen
            auf Grundlage von Art. 6 Abs. 1 lit. f) DSGVO. Im Rahmen von Google
            Ads nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf
            eine von Google geschaltete Anzeige klicken wird ein Cookie für das
            Conversion-Tracking gesetzt. Diese Cookies verlieren nach 30 Tagen
            ihre Gültigkeit und dienen nicht der persönlichen Identifizierung
            der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und
            das Cookie ist noch nicht abgelaufen, können Google und wir
            erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
            Seite weitergeleitet wurde.
          </P>
          <P>
            Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies
            können nicht über die Websites von AdWords-Kunden nachverfolgt
            werden. Die mithilfe des Conversion-Cookies eingeholten
            Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden
            zu erstellen, die sich für Conversion-Tracking entschieden haben.
            Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre
            Anzeige geklickt haben und zu einer mit einem
            Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie
            erhalten jedoch keine Informationen, mit denen sich Nutzer
            persönlich identifizieren lassen.
          </P>
          <P>
            Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser
            Nutzung widersprechen, indem Sie das Cookie des Google
            Conversion-Trackings über ihren Internet-Browser unter
            Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in
            die Conversion-Tracking Statistiken aufgenommen.
          </P>
          <P>
            Google Ads Remarketing ermöglicht uns, Ihnen zielgruppenspezifische
            Werbung über das Google Werbenetzwerk anzuzeigen. Hierzu übermitteln
            wir an Google ggf. Daten über die von Ihnen angesehenen Angebote
            bzw. bestimmte damit zusammenhängende Merkmale (z.B. Interessen an
            bestimmten Themen oder Produkten, die anhand der besuchten Webseiten
            bestimmt werden). Google verwendet diese Daten, um Ihnen
            zielgruppenspezifische Werbung beim Besuch unserer Seite oder unsere
            Werbung auf ande-ren Seiten des Google Netzwerks auszuspielen. Mit
            Hilfe der Remarketing Audiences möchten wir auch sicherstellen, dass
            unsere Anzeigen dem potentiellen Interesse der Nutzer entsprechen.
          </P>
          <P>
            Wir verwenden Google Dynamic Remarketing zu Marketing- und
            Optimierungszwecken, insbesondere um für Sie relevante und
            interessante Anzeigen zu schalten, die Berichte zur
            Kampagnenleistung zu verbessern und eine faire Berechnung von
            Werbekosten zu erreichen. Hierin liegt auch unser berechtigtes
            Interesse an der Verarbeitung der vorstehenden Daten durch den
            Drittanbieter. Die erforderlichen Verarbeitungen erfolgen auf
            Grundlage von Art. 6 Abs. 1 lit. f) DSGVO.
          </P>
          <P>
            Die Installation von Cookies können Sie verhindern, indem Sie
            vorhandene Cookies lö-schen und eine Speicherung von Cookies in den
            Einstellungen Ihres Webbrowsers deak-tivieren. Wir weisen darauf
            hin, dass Sie in diesem Fall möglicherweise nicht alle Funktionen
            unserer Webseite vollumfänglich nutzen können. Die Verhinderung der
            Speicherung von Cookies ist auch dadurch möglich, dass Sie Ihren
            Webbrowser so einstellen, dass Cookies von der Domain
            „WWW.GOOGLEADSERVICES.COM“ blockiert werden.
          </P>
          <P>
            Zudem können Sie widersprechen, indem Sie auf der folgenden Seite
            die entsprechenden Einstellungen setzen:
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.de/SETTINGS/ADS"
            >
              HTTPS://WWW.GOOGLE.DE/SETTINGS/ADS
            </a>
            <br />
            Wir weisen Sie darauf hin, dass diese Einstellung gelöscht wird,
            wenn Sie Ihre Cookies löschen.
          </P>
          <P>
            Zudem können Sie interessenbezogene Anzeigen über den Link&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.aboutads.info/CHOICES"
            >
              HTTP://WWW.ABOUTADS.INFO/CHOICES
            </a>
            <br />
            deaktivieren. Wir weisen Sie darauf hin, dass auch diese Einstellung
            gelöscht wird, wenn Sie Ihre Cookies löschen.
            <br />
            Mehr Informationen zu Google AdWords und Google Conversion-Tracking
            finden Sie in den Datenschutzbestimmungen von Google:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.de/POLICIES/PRIVACY/"
            >
              HTTPS://WWW.GOOGLE.DE/POLICIES/PRIVACY/
            </a>
          </P>
          <ul>
            <li>
              <Bold>LinkedIn Insight Tag</Bold>
            </li>
          </ul>
          <P>
            Unsere Website verwendet das Conversion Tool „LinkedIn Insight Tag“
            der LinkedIn Ireland Unlimited Company. Zweck des LinkedIn Insight
            Tag ist unser berechtigtes Interesse an der Einblendung und
            Optimierung von Werbung (Art. 6 Abs. 1 lit. f DSGVO).
          </P>
          <P>
            Dieses Tool erstellt ein Cookie in Ihrem Webbrowser, welches die
            Erfassung u.a. folgender Daten ermöglicht: IP-Adresse, Geräte- und
            Browsereigenschaften und Seitenereignisse (z.B. Seitenabrufe). Diese
            Daten werden verschlüsselt, innerhalb von sieben Tagen anonymisiert
            und die anonymisierten Daten werden innerhalb von 90 Tagen gelöscht.
            LinkedIn teilt keine personenbezogenen Daten mit ETL-Gruppe sondern
            bietet anonymisierte Berichte über die Webseiten-Zielgruppe und die
            Anzeigeleistung an. Ergänzend bietet LinkedIn über das Insight Tag
            die Möglichkeit eines Retargetings. Die ETL-Gruppe kann mit Hilfe
            dieser Daten zielgerichtete Werbung außerhalb seiner Website
            anzeigen, ohne dass Sie als Websitebesucher dabei identifiziert
            werden. Nähere Informationen zum Datenschutz bei LinkedIn können Sie
            den LinkedIn Datenschutzhinweisen entnehmen.
          </P>
          <P>
            Mitglieder von LinkedIn können die Nutzung ihrer personenbezogenen
            Daten zu Werbezwecken in ihren Kontoeinstellungen steuern. Um das
            Insight-Tag auf unserer Website zu deaktivieren („Opt-out“) klicken
            Sie&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
            >
              hier
            </a>
            .
          </P>
          <P>
            <u>Datenverarbeitungsvereinbarung mit LinkedIn:&nbsp;</u>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://de.linkedin.com/legal/l/dpa"
            >
              https://de.linkedin.com/legal/l/dpa
            </a>
          </P>
          <ul>
            <li>
              <Bold>
                Twitter Advertising (Retargeting bzw. Conversion Tracking)
              </Bold>
            </li>
          </ul>
          <P>
            Wir nutzen auf unsere Website Dienste von Twitter Inc., 1355 Market
            Street, Suite 900, San Francisco, CA 94103, USA. Verantwortliche
            Stelle für den Umgang mit Betroffenenrechten ist innerhalb der EU/
            des EWR die
          </P>
          <P>
            Twitter International Company
            <br />
            Attn: Data Protection Officer
            <br />
            One Cumberland Place, Fenian Street
            <br />
            Dublin 2, D02 AX07 IRLAND.
          </P>
          <P>
            Twitter Ads ermöglicht es Werbetreibenden, Daten von den Nutzern zu
            sammeln, die ihre Website besuchen. Zweck der Cookies ist unser
            berechtigtes Interesse an der Einblendung und Optimierung von
            Werbung (Art. 6 Abs. 1 lit. f DSGVO).
          </P>
          <P>
            Es werden Cookies und Code eingesetzt, die die Website mit einer
            anderen Plattform von Drittanbietern wie Twitter verbinden. Dabei
            wird eine nicht-reversible und nicht-personenbezogene Prüfsumme
            (Hash-Wert) aus Ihren Nutzungsdaten generiert und zu Analyse- und
            Marketingzwecken an Twitter übermittelt. Darüber hinaus kann ein
            sog. „Twitter-Pixel“ zum Einsatz kommen, mit dessen Hilfe die
            Aktionen von Nutzern verfolgt werden können, nachdem diese eine
            Twitter-Werbeanzeige gesehen oder geklickt haben.
          </P>
          <P>
            Erfasst wird das Nutzerverhalten, wie z.B. besuchte Webseiten,
            abgerufene Inhalte, Zeitpunkt des Besuchs etc., aber auch
            gerätebezogene Daten wie z.B. genutzte Anwendungen und
            Betriebssysteme. Zur geografischen Aussteuerung von Werbung wird
            Ihre IP- Adresse gespeichert und verwendet. Bei der
            „geräteübergreifenden Personalisierung“ versucht Twitter außerdem,
            alle Geräte eines Nutzers zu identifizieren und zu verknüpfen. Da
            die Daten von Twitter gespeichert und verarbeitet werden, ist auch
            eine Verbindung zum jeweiligen Nutzerprofil auf twitter.com möglich.
          </P>
          <P>
            Anonymisierte Daten werden innerhalb von 6 Monaten gelöscht. Daten,
            die es ermöglichen, einen bestimmten Benutzer auf Twitter zu
            identifizieren, werden innerhalb von 90 Tagen gelöscht. Weitere
            Informationen zur Dauer der Speicherung erfahren Sie beim Anbieter
            oder unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://legal.twitter.com/ads-terms/international.html"
            >
              https://legal.twitter.com/ads-terms/international.html
            </a>
            .
          </P>
          <P>
            Sie können der Datenerfassung durch Twitter widersprechen, indem Sie
            die Werbeeinstellungen in Ihrem Twitter-Account anpassen oder
            unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/personalization"
            >
              https://twitter.com/personalization
            </a>
            .
          </P>
          <P>
            Weitere Informationen über Zweck und Umfang der Datenerhebung und
            die weitere Verarbeitung und Nutzung der Daten, sowie die
            Privatsphäreeinstellungen können Sie den Datenschutzrichtlinien von
            Twitter entnehmen:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/privacy"
            >
              https://twitter.com/privacy
            </a>
            .
          </P>
          <ul>
            <li>
              <Bold>Facebook Custom Audiences (Facebook-Pixel)</Bold>
            </li>
          </ul>
          <P>
            Wir nutzen im Rahmen der nutzungsbasierten Onlinewerbung den Dienst
            Custom Audiences der Facebook Inc. (1601 S. California Avenue, Palo
            Alto, CA 94304, USA).
          </P>
          <P>
            Zweck der Cookies ist unser berechtigtes Interesse an der
            Einblendung und Optimierung von Werbung (Art. 6 Abs. 1 lit. f
            DSGVO).
          </P>
          <P>
            Zu diesem Zweck legen wir im Facebook-Werbeanzeigenmanager
            Zielgruppen von Nutzern auf Basis bestimmter Merkmale fest, die
            nachfolgend Werbeanzeigen innerhalb des Facebook-Netzwerks angezeigt
            bekommen. Die Nutzer werden von Facebook anhand der von ihnen
            angegebenen Profilinformationen sowie sonstigen durch die Benutzung
            von Facebook bereitgestellten Daten ausgewählt. Klickt ein Nutzer
            auf eine Werbeanzeige und gelangt anschließend auf unsere Website,
            erhält Facebook über das auf unserer Website eingebundene
            Facebook-Pixel die Information, dass der Nutzer auf das Werbebanner
            geklickt hat. Grundsätzlich wird dabei eine nicht-reversible und
            nicht-personenbezogene Prüfsumme (Hash-Wert) aus Ihren Nutzungsdaten
            generiert, die an Facebook zu Analyse-und Marketingzwecken
            übermittelt wird. Dabei wird ein Facebook-Cookie gesetzt. Dieser
            erfasst Informationen über Ihre Aktivitäten auf unserer Webseite
            (z.B. Surfverhalten, besuchte Unterseiten, etc.). Zur geografischen
            Aussteuerung von Werbung wird zudem Ihre IP-Adresse gespeichert und
            verwendet. Facebook Custom Audiences über die Kundenliste wird
            ebenso wie die Funktion „erweiterter Abgleich“ nicht von uns
            genutzt.
          </P>
          <P>
            Weitere Informationen über Zweck und Umfang der Datenerhebung, die
            weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre
            Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre, können Sie
            den Datenschutzrichtlinien von Facebook entnehmen. Einstellungen
            dazu, welche Werbeanzeigen Ihnen auf Facebook angezeigt werden,
            können Sie auch unter diesem Link sowie in den Kontoeinstellungen
            von Facebook vornehmen.
          </P>
          <P>
            Weitere Informationen zu dem Dienst Custom Audiences von Facebook
            finden sie unter:
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://de-de.facebook.com/business/help/449542958510885"
            >
              https://de-de.facebook.com/business/help/449542958510885
            </a>
            .
          </P>
          <P>
            Weitere Informationen zur Datenverarbeitung und Speicherdauer
            erhalten sie bei dem Anbieter oder unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/about/privacy"
            >
              https://www.facebook.com/about/privacy
            </a>
            .
          </P>
          <P>
            Die Deaktivierung der Funktion „Facebook Custom Audiences“ ist für
            eingeloggte Nutzer unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/settings/?tab=ads"
            >
              https://www.facebook.com/settings/?tab=ads#
            </a>
            &nbsp;möglich.
          </P>
          <P>
            Sie können die Speicherung von Cookies zudem durch eine
            entsprechende Einstellung Ihrer Browser-Software insgesamt
            verhindern. Wir weisen jedoch darauf hin, dass Sie in diesem Fall
            gegebenenfalls nicht sämtliche Funktionen unserer Webseite
            vollumfänglich werden nutzen können.
          </P>
          <ul>
            <li>
              <Bold>Einsatz von HubSpot</Bold>
            </li>
          </ul>
          <P>
            Wir setzen auf unseren Webseiten zu Analysezwecken HubSpot, einen
            Dienst der HubSpot Inc., 25 Frist Street, Cambridge, MA 02141 USA,
            ein.
          </P>
          <P>
            Hierbei werden sog. „Web-Beacons“ verwendet und auch „Cookies“
            gesetzt, die auf Ihrem Computer gespeichert werden und die eine
            Analyse Ihrer Benutzung der Website durch uns ermöglichen.
            „Web-Beacons“ bezeichnen eine Softwaretechnologie, die eine ähnliche
            Funktion wie Cookies haben. Die erfassten Informationen (z.B.
            IP-Adresse, der geographische Standort, Art des Browsers, Dauer des
            Besuchs und aufgerufene Seiten) wertet HubSpot im Auftrag von uns
            aus, um Reports über den Besuch und die besuchten Seiten von ETL zu
            generieren.
          </P>
          <P>
            Wenn wie in Ziff. 3c dargestellt ETL-E-Mail-News abonniert und
            Studien sowie andere Dokumente bezogen werden, können wir mit
            HubSpot die Besuche eines Nutzers auf ETL-Webseiten auch mit den
            persönlichen Angaben (v.a. Name/E-Mail-Adresse) auf der Basis einer
            erteilten Einwilligung verknüpfen, somit personenbezogen erfassen
            und Nutzer individuell und zielgerichtet über bevorzugte
            Themengebiete informieren.
          </P>
          <P>
            Falls eine Erfassung durch HubSpot generell nicht gewünscht ist,
            kann die Speicherung von Cookies jederzeit durch entsprechende
            Browsereinstellungen verhindert werden (siehe oben unter Ziff. 3e).
            <br />
            Weitere Informationen über die Funktionsweise und der möglichen
            Datenübermittlung von HubSpot in die USA sind in der
            Datenschutzerklärung der HubSpot Inc. zu finden, abzurufen
            unter:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="http://legal.hubspot.com/de/privacy-policy"
            >
              http://legal.hubspot.com/de/privacy-policy
            </a>
          </P>
          <ul>
            <li>
              <Bold>Vimeo</Bold>
            </li>
          </ul>
          <P>
            Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
            Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.Wenn
            Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten
            besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
            Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
            besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch
            dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account
            bei Vimeo besitzen. Die von Vimeo erfassten Informationen werden an
            den Vimeo-Server in den USA übermittelt. Wenn Sie in Ihrem
            Vimeo-Account eingeloggt sind, ermöglichen Sie Vimeo, Ihr
            Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies
            können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account
            ausloggen. Zur Wiedererkennung der Websitebesucher verwendet Vimeo
            Cookies bzw. vergleichbare Wiedererkennungstechnologien (z. B.
            Device-Fingerprinting).
            <br />
            Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar. Die Datenübertragung in die
            USA wird auf die Standardvertragsklauseln der EU-Kommission sowie
            nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“
            gestützt. Details finden Sie hier:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vimeo.com/privacy"
            >
              https://vimeo.com/privacy
            </a>
            <br />
            Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Vimeo unter:&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vimeo.com/privacy"
            >
              https://vimeo.com/privacy
            </a>
          </P>
          <ul>
            <li>
              <Bold>Youtube</Bold>
            </li>
          </ul>
          <P>
            Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die
            auf http://www.YouTube.com gespeichert sind und von unserer Website
            aus direkt abspielbar sind. Betreiber der Plattform ist Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
          </P>
          <P>
            Die YouTube-Videos wurden von uns über den YouTube-Nocookie-Link im
            „erweiterten Datenschutz-Modus“ eingebunden. Dies bedeutet, dass
            keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn
            Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen,
            werden die im folgenden Absatz genannten Daten übertragen. Auf diese
            Datenübertragung haben wir keinen Einfluss.
          </P>
          <P>
            Durch den Besuch auf der Website erhält YouTube die Information,
            dass Sie die entsprechende Unterseite unserer Website aufgerufen
            haben. Zudem werden die unter Ziffer 7 dieser Erklärung genannten
            Daten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein
            Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein
            Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
            Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit
            Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor
            Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als
            Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
            und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
            Auswertung erfolgt insbesondere (selbst für nicht eingeloggte
            Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere
            Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer
            Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen
            die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen
            an YouTube richten müssen.
          </P>
          <P>
            Weitere Informationen zu Zweck und Umfang der Datenerhebung und
            ihrer Verarbeitung durch YouTube erhalten Sie in der&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://policies.google.com/privacy?gl=DE&amp;hl=de"
            >
              Datenschutzerklärung von Google
            </a>
            . Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und
            Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre. Google
            verarbeitet Ihre personenbezogenen Daten auch in den USA.
          </P>
          <P>
            <Bold>10. Einsatz von Social Media Plug-ins</Bold>
          </P>
          <P>
            Wir haben auf unserer Website externe Links der Social Media
            Netzwerke Facebook und Twitter eingebunden. Dies dient dazu unsere
            Webseite bekannter zu machen. Der werbliche Zweck ist ein
            berechtigtes Interesse im Sinne der DS-GVO. Die Einbindung dieser
            Links durch uns erfolgt im Wege der sogenannten
            „Zwei-Klick-Methode“, um Besucher unserer Website bestmöglich zu
            schützen.
          </P>
          <P>
            Sofern Sie in das Setzen von Cookies eingewilligt haben und die
            Links der jeweiligen Anbieter anklicken, können auf Grundlage des
            Artikel 6 Absatz 1 Satz 1 Buchstabe a DS-GVO Cookies dieser Anbieter
            in Ihrem Browser abgelegt werden.
          </P>
          <P>
            <Bold>a) Facebook</Bold>
          </P>
          <P>
            Wir haben auf unserer Website einen externen Link eingebunden, mit
            dem Sie sich auf unseren Internetauftritt bei Facebook weiterleiten
            lassen können. Sie erkennen diesen Button an dem Facebook-Logo
            (f-Button). Bei Facebook handelt es sich um ein Angebot der Facebook
            Inc., 1601 S California Ave, Palo Alto, California 94304, USA. Wenn
            Sie durch das Betätigen des Buttons unsere Website auf Facebook
            aufrufen, baut Ihr Browser eine Verbindung mit den Servern von
            Facebook auf.
          </P>
          <P>
            Durch den Verbindungsaufbau erhält Facebook die Information, dass
            Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen
            hat. Dies erfolgt auch dann, wenn Sie kein Facebook-Konto besitzen
            oder nicht bei Facebook eingeloggt sind. Diese Information
            (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an
            einen Server von Facebook in den USA übermittelt und dort
            gespeichert. Auf die über die Facebook-Website von Facebook
            erhobenen Daten haben wir keinen Einfluss. Wir verweisen aber auf
            die zusätzlich zu unserer Datenschutzerklärung geltende&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/about/privacy/"
            >
              Datenschutzerklärung von Facebook
            </a>
            .
          </P>
          <P>
            Wenn Sie bei Facebook eingeloggt sind, kann Facebook den Besuch
            unserer Facebook-Website Ihrem Facebook-Konto direkt zuordnen. Wenn
            Sie mit dort vorhandenden Plug-ins interagieren, zum Beispiel den
            „LIKE“ oder „TEILEN“-Button betätigen, wird die entsprechende
            Information ebenfalls direkt an einen Server von Facebook
            übermittelt und dort gespeichert. Die Informationen werden zudem auf
            Facebook veröffentlicht und Ihren Facebook-Freunden angezeigt.
          </P>
          <P>
            Facebook kann diese Informationen zum Zwecke der Werbung,
            Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten
            benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und
            Beziehungsprofile erstellt. Dies erfolgt z. B. um Ihre Nutzung
            unserer Website im Hinblick auf die Ihnen bei Facebook
            eingeblendeten Werbeanzeigen auszuwerten. Ebenfalls können andere
            Facebook-Nutzer über Ihre Aktivitäten auf unserer Website informiert
            werden. Dies erfolgt auch, um weitere mit der Nutzung von Facebook
            verbundene Dienstleistungen zu erbringen.
          </P>
          <P>
            Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt
            gesammelten Daten Ihrem Facebook-Konto zuordnet, müssen Sie sich vor
            Ihrem Besuch unserer Website bei Facebook ausloggen.
          </P>
          <P>
            Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
            Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte
            und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre
            entnehmen Sie bitte den Datenschutzhinweisen&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/about/privacy/"
            >
              (https://www.facebook.com/about/privacy/
            </a>
            ) von Facebook.
          </P>
          <P>
            <Bold>b) Twitter</Bold>
          </P>
          <P>
            Auf unserer Website ist ein externer Link auf unseren
            Internetauftritt des Kurznachrichtennetzwerks der Twitter Inc.
            (Twitter) integriert. Diesen erkennen Sie am Twitter-Logo
            (tweet-Button) auf unserer Website. Wenn Sie durch das Betätigen des
            Buttons unsere Website bei Twitter aufrufen, baut Ihr Browser eine
            Verbindung mit den Servern von Twitter auf. Dort können Sie
            zusätzliche Informationen von uns erhalten. Bei Twitter handelt es
            sich um ein Angebot von Twitter International Company, One
            Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Ireland.
          </P>
          <P>
            Auf die über die Twitter-Website von Twitter erhobenen Daten haben
            wir keinen Einfluss. Wir verweisen aber auf die zusätzlich zu
            unserer Datenschutzerklärung geltende&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/privacy"
            >
              Datenschutzerklärung von Twitter
            </a>
            .
          </P>
          <P>
            Wenn Sie auf einer Seite unseres Webauftritts auf den
            Twitter-„tweet“-Button klicken, wird eine direkte Verbindung
            zwischen Ihrem Browser und dem Twitter-Server hergestellt. Twitter
            erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere
            Seite besucht haben.
          </P>
          <P>
            Wenn Sie den Twitter-„tweet-Button“ anklicken, während Sie in Ihrem
            Twitter-Account eingeloggt sind, können Sie die Inhalte unserer
            Website mit Ihrem Twitter-Profil verlinken. Dadurch kann Twitter den
            Besuch unserer Website Ihrem Benutzerkonto zuordnen.
          </P>
          <P>
            Wenn Sie mit den Plug-ins („LIKE“ oder „TEILEN“-Button) auf unserer
            Website bei Twitter interagieren, wird die entsprechende Information
            ebenfalls direkt an einen Server von Twitter übermittelt und dort
            gespeichert.
          </P>
          <P>
            Wir weisen darauf hin, dass wir keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Twitter erhalten.
          </P>
          <P>
            Wenn Sie nicht wünschen, dass Twitter den Besuch unserer Seiten
            zuordnen kann, loggen Sie sich bitte aus Ihrem Twitter-Benutzerkonto
            aus.
          </P>
          <P>
            Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
            von Twitter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/privacy"
            >
              (https://twitter.com/privacy)
            </a>
            .
          </P>
          <P>
            <Bold>11. Google Maps</Bold>
          </P>
          <P>
            Wir nutzen auf unserer Webseite das Angebot von Google Maps. Die
            Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote und an einer leichten
            Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DS-GVO dar.
          </P>
          <P>
            Durch den Besuch unserer Webseite erhält Google die Information,
            dass Sie die entsprechende Unterseite unserer Webseite aufgerufen
            haben. Darüber hinaus werden die unter Punkt 7 a) genannten Daten
            übermittelt. Dies erfolgt unabhängig davon, ob Google ein
            Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein
            Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
            Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit
            Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor
            Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als
            Nutzungsprofile und nutzt sie für Werbezwecke, Marktforschung und
            bedarfsgerechte Gestaltung seiner Webseite. Eine solche Aufwertung
            erfolgt insbesondere (auch für nicht eingeloggte Nutzer), um
            bedarfsorientierte Werbung zu platzieren und um andere Nutzer des
            sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu
            informieren. Ihnen steht ein Widerspruchsrecht gegen die Bildung
            solcher Nutzerprofile zu, das Sie jedoch gegenüber Google ausüben
            müssen.
          </P>
          <P>
            Die Nutzungsbedingungen von Google können Sie unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.de/intl/de/policies/terms/regional.html"
            >
              https://www.google.de/intl/de/policies/terms/regional.html
            </a>
            &nbsp;einsehen, die zusätzlichen Nutzungsbedingungen für Google Maps
            finden Sie unter&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/intl/de_US/help/terms_maps.html"
            >
              https://www.google.com/intl/de_US/help/terms_maps.html
            </a>
          </P>
          <P>
            Ausführliche Informationen zum Datenschutz im Zusammenhang mit der
            Verwendung von Google Maps finden Sie auf der Internetseite von
            Google („Google Privacy Policy“):&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.de/intl/de/policies/privacy/"
            >
              https://www.google.de/intl/de/policies/privacy/
            </a>
          </P>
          <P>
            <Bold>12. Datensicherheit</Bold>
          </P>
          <P>
            Zur Sicherstellung eines fehlerfreien Betriebs können wir gemäß
            Artikel 6 Abs. 1 Satz 1 Buchstabe f DS-GVO sogenannte Logfiles
            erstellen. Hierbei handelt es sich um Protokolldateien, die
            Programmereignisse festhalten.
          </P>
          <P>
            Die an uns übermittelten Informationen werden auf Servern innerhalb
            der Europäischen Union gespeichert.
          </P>
          <P>
            Wir weisen ausdrücklich darauf hin, dass dies bei der Verwendung von
            Analyse Tools oder Social Media anders sein kann. Zudem weisen wir
            darauf hin, dass der Transfer von Daten über das Internet nicht
            vollständig sicher ist. Die absolute Sicherheit für die Übermittlung
            von Informationen über das Internet an uns können wir daher nicht
            garantieren.
          </P>
          <P>
            Wir verwenden im Rahmen des Website-Besuchs das verbreitete
            SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils
            höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
            wird. In der Regel handelt es sich dabei um eine 256 Bit
            Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung
            unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
            zurück. Ob eine einzelne Seite unserer Website verschlüsselt
            übertragen wird, erkennen Sie an der geschlossenen Darstellung des
            Schlüssel- beziehungsweise Schloss-Symbols in der unteren
            Statusleiste Ihres Browsers.
          </P>
          <P>
            Wir setzen geeignete technische und organisatorische
            Sicherheitsmaßnahmen ein, um Ihre Daten gegen zufällige oder
            vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust,
            Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen,
            soweit dies in unserer Macht steht. Unsere Sicherheitsmaßnahmen
            werden entsprechend der technologischen Entwicklung fortlaufend
            verbessert.
          </P>
          <P>
            <Bold>13. Aktualität und Änderung dieser Datenschutzerklärung</Bold>
          </P>
          <P>
            Wir entwickeln unsere Website und die von uns angebotenen Dienste
            fortlaufend weiter. Insbesondere wenn neue Technologien
            implementiert und eingesetzt werden, kann es erforderlich werden,
            diese Datenschutzerklärung zu ändern. Dasselbe gilt bei gesetzlichen
            Änderungen oder behördlichen Vorgaben. Wir empfehlen Ihnen daher,
            sich diese Datenschutzerklärung nicht nur jetzt, sondern von Zeit zu
            Zeit erneut aufmerksam durchzulesen.
          </P>
          <P>Diese Datenschutzerklärung hat den Stand November 2022.</P>
        </Container>
      </StyledDataProtection>
    </Layout>
  );
};

export default DataProtection;
