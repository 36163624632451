import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledTopics = styled.div`
  background-color: ${({ theme }) => theme.color.background.fourth};
  padding: 75px 0;
`;
export const TopicContainer = styled(Container)`
  display: flex;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
export const Topic = styled.div``;
export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
`;
export const Text = styled.div`
  margin-top: 25px;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
`;
