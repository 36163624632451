// import { Container } from "@think-internet/ui-components";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledPeople,
  List,
  Person,
  Image,
  Name,
  Title,
  Text,
  Value,
  ValueLink,
} from "./People.Styled";
import { getUUID } from "../../../utility";
import Etl_GF_1 from "../../../assets/image/etl-protax/people/Etl_GF_1.jpg";
import Etl_Gf_2 from "../../../assets/image/etl-protax/people/Etl_Gf_2.jpg";
import Etl_Gf_3 from "../../../assets/image/etl-protax/people/Etl_Gf_3.jpg";
import Verena from "../../../assets/image/etl-protax/people/Verena.jpg";
import Flag from "../../SubComponents/Flag/Flag";

const People = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [Etl_GF_1, Etl_Gf_2, Etl_Gf_3, Verena];
  return (
    <StyledPeople>
      {/* <Container> */}
      <List>
        {translation.etlProtax.people.list.map((p, i) => (
          <Person key={getUUID()}>
            <Image src={images[i]} />
            <Name>{`${p.firstname} ${p.lastname}`}</Name>
            <Title>{p.title}</Title>
            {p.text && <Text>{p.text}</Text>}
            <Value>
              <Flag width="20px" />
              <ValueLink href={`mailto:${p.mail}`}>{p.mail}</ValueLink>
            </Value>
          </Person>
        ))}
      </List>
      {/* </Container> */}
    </StyledPeople>
  );
};

export default People;
