import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import { getUUID } from "../../../../utility";

const {
  StyledTopics,
  TopicContainer,
  Topic,
  Headline,
  Text,
} = require("./Topics.Style");

const Topics = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledTopics>
      <TopicContainer>
        {translation.etlProtax.goals.topics.map(({ headline, text }) => (
          <Topic key={getUUID()}>
            <Headline>{headline}</Headline>
            <Text>{text}</Text>
          </Topic>
        ))}
      </TopicContainer>
    </StyledTopics>
  );
};

export default Topics;
