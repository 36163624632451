//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  header: {
    pages: [
      { name: "ETL PROTAX", href: "/etl-protax" },
      { name: "Leistungen", href: "/leistungen" },
      { name: "Standorte", href: "/standorte" },
      { name: "Karriere", href: "/karriere" },
      { name: "Kontakt", href: "/kontakt" },
      {
        name: "Newsletter",
        href: "https://www.etl.de/newsletter/",
        mobileOnly: true,
      },
      {
        name: "Datev Unternehmen Online",
        href: "https://webapps.datev.de/duo-start",
        mobileOnly: true,
      },
      {
        name: "Datev Arbeitnehmer Online",
        href: "https://apps.datev.de/ano/",
        mobileOnly: true,
      },
      { name: "Impressum", href: "/impressum", mobileOnly: true },
      { name: "Datenschutz", href: "/datenschutz", mobileOnly: true },
    ],
  },

  home: {
    rise: {
      title: ["RISE"],
      by: "by lifting",
      others: "OTHERS",
      cta: "Weitere Infos >",
    },
    career: {
      subtitle: "KARRIERE",
      title: ["Viele Menschen", "mit einem Ziel"],
      by: "Team-Member bei ETL PROTAX werden",
      cta: "Weitere Infos >",
    },
    services: {
      subtitle: "LEISTUNGEN",
      title: ["Dein Unternehmen in", "besten Händen"],
      cta: "Weitere Infos >",
    },
    locations: {
      subtitle: "STANDORTE",
      title: ["Viele Menschen", "mit einem Ziel"],
      by: "Team-Member bei ETL PROTAX werden",
      cta: "Weitere Infos >",
    },
    contact: {
      subtitle: "KONTAKT",
      title: ["Egal worum es geht", "- Sprich uns an"],
      by: "Let's Talk",
      cta: "Weitere Infos >",
    },
    popup: {
      title: "E-Rechnungspflicht ab dem 01.01.2025",
      text: [
        'Ab dem 01.01.2025 ist jedes Unternehmen dazu verpflichtet, sogenannte E-Rechnungen empfangen und verarbeiten zu können. Nähere Informationen zum Thema E-Rechnung erhalten Sie <a class="underline" href="https://s3.eu-central-1.amazonaws.com/think-internet-gmbh.etl.homepage/CUSTOM_STATIC_ASSETS/205715_0424_MB_+Ausstellung+und+Empfang+elektronischer+Rechnungen_g.pdf" target="_blank">hier</a>.',
        "Diese gesetzliche Vorgabe erfordert eine sofortige Überprüfung und Umstellung der teils noch auf Papierbelegen beruhenden Belegverwaltung in Ihrem Tagesgeschäft. Zwar trifft die Unternehmen die Verpflichtung zum Versand von E-Rechnungen  je nach Unternehmensgröße erst ab dem 01.01.2026, bzw. 01.01.2027, jedoch erfordert eine Umstellung auf eine Digitale Belegverwaltung in Ihrem Unternehmen sicherlich eine gewisse Vorlaufzeit.",
        "Wir unterstützen Sie gerne bei der Umsetzung der sich aus den gesetzlichen Vorgaben ergebenden Änderungen.",
      ],
    },
  },

  contact: {
    headline: "KONTAKT",
    form: {
      label: "Melde dich bei uns",
      cta: "Senden",
      success: "Deine Nachricht wurde erfolgreich versendet.",
      error: "Deine Nachricht konnte nicht versendet werden.",
    },
    companies: [
      {
        name: "ETL Protax GmbH",
        nameType: "Steuerberatungsgesellschaft",
        street: "Im Bruch 18",
        city: "63897 Miltenberg",
        mail: "info@etl-protax.de",
        phone: "+49 (0) 9371 / 99800",
      },
      {
        name: "ETL Protax GmbH",
        nameType: "Steuerberatungsgesellschaft",
        street: "Magnolienweg 3",
        city: "63741 Aschaffenburg",
        mail: "info@etl-protax.de",
        phone: "+49 (0) 9371 / 99800",
      },
    ],
  },

  etlProtax: {
    boxes: [
      {
        headline: "STEUERN RECHTSSICHER OPTIMIEREN",
        text: "ETL Protax unterstützt dich dabei, Steuervorteile und -ermäßigungen zu nutzen und somit deine Steuerlast zu minimieren. Unser Ziel ist es, dass du nur die Steuern zahlst, die wirklich notwendig sind.",
      },
      {
        headline: "UNTERNEHMEN ERFOLGREICH FÜHRE",
        text: "ETL Protax hilft dir dabei, dein Unternehmen steuerlich und wirtschaftlich optimal aufzustellen. Unser Ziel ist es, dass du dich auf dein Kerngeschäft konzentrieren kannst, während wir uns um deine Finanzen kümmern.",
      },
      {
        headline: "AUF DEINE ZUKUNFT VORBEREITEN",
        text: "ETL Protax berät dich umfassend zu steuerlichen Themen, die auf die Zukunft vorbereiten. Unser Ziel ist es, dass du langfristig erfolgreich bist und deine finanziellen Ziele erreichen kannst.",
      },
    ],
    textBlock: {
      headline: "WIR BEI ETL PROTAX",
      text: "Wir sind eine regional verwurzelte, überregional und international agierende Steuerberatungsgesellschaft. Wir handeln zukunftsorientiert und beraten unsere Mandanten ganzheitlich. Unsere Mitarbeiter erklären Steuern, bilanzieren, prüfen, beraten, recherchieren, entscheiden und tragen Verantwortung – immer unterstützt von unserem aufgeschlossenen Geschäftsführer-Team. Wir konzentrieren uns auf mittelständische Unternehmen und Freiberufler, die wir proaktiv an unseren Standorten in Aschaffenburg und Miltenberg betreuen.",
    },
    goals: {
      headerText: "UNSERE ZIELE",
      topics: [
        {
          headline: "Kundenerfolg durch Teamwork",
          text: "Mit Fokus auf Teamzusammenhalt und Work-Life-Balance ermöglicht ETL PROTAX ihren Kunden optimale Ergebnisse. Durch Innovation und digitales Denken verbessern wir kontinuierlich unsere Arbeitsabläufe und schaffen Wettbewerbsvorteile.",
        },
        {
          headline: "Partner für Wachstum",
          text: "ETL PROTAX strebt danach, Unternehmen bei der Identifizierung neuer Geschäftsmöglichkeiten, der Überarbeitung von Geschäftsstrategien und der Begleitung von Nachfolgeprozessen zu helfen, um langfristigen Erfolg sicherzustellen.",
        },
        {
          headline: "Innovation in der Steuerberatung",
          text: "ETL Protax unterstützt Unternehmen bei der Digitalisierung ihrer Steuerprozesse und setzt auf innovative Technologien. Unser Ziel ist es, dass Steuerberatung zeitgemäß und effizient ist und so den Wandel der Arbeitswelt unterstützt.",
        },
      ],
      values: {
        headline: "UNSERE WERTE",
        points: [
          {
            headline: "FAMILIE",
            text: "Wir verstehen, dass außerhalb der Arbeit auch wichtigere Verpflichtungen bestehen. So legen wir Wert auf die Vereinbarkeit von Beruf und Familie und bemühen uns, ein unterstützendes und flexibles Arbeitsumfeld für alle unsere Kollegen zu schaffen. Es bestehen Optionen wie flexible Arbeitszeiten und Home-Office, um unseren Kollegen zu helfen, ihre beruflichen und familiären Verpflichtungen effektiv zu bewältigen. Wir sind davon überzeugt, dass die Unterstützung der Vereinbarkeit von Beruf und Familie dazu beiträgt, die richtigen Menschen zu halten und anzuziehen, und damit eine positive und gesunde Arbeitskultur fördert.",
          },
          {
            headline: "INNOVATION",
            text: "Innovation ist ein zentraler Wert in unserer Steuerberatungsgesellschaft. Wir sind davon überzeugt, dass ständiges Lernen und Entwickeln sowie die Fähigkeit, kreativ zu denken und sich an Veränderungen anzupassen, erforderlich sind, um an der Spitze der Branche zu bleiben. Wir ermutigen unsere Kollegen, neue Ideen einzubringen, und unterstützen sie in ihren Bemühungen, Prozesse kontinuierlich zu verbessern und neue Wege zu finden, um für unsere Mandanten einen Mehrwert zu schaffen. Schulungen und berufliche Weiterbildungsmöglichkeiten sehen wir als Investition, damit unsere Kollegen über die neuesten Trends und Technologien in diesem Bereich auf dem Laufenden bleiben. Durch die Förderung einer Innovationskultur wollen wir Wachstum und Erfolg für unser Unternehmen und unsere Kunden vorantreiben.",
          },
          {
            headline: "ZUKUNFT",
            text: "In unserer Steuerberatungsgesellschaft verpflichten wir uns, eine solide Zukunft für unsere Kollegen und Mandanten zu schaffen. Wir glauben, dass eine langfristige Planung für den Erfolg unerlässlich ist und ermutigen unsere Kollegen, die langfristigen Auswirkungen ihrer Arbeit und ihrer Entscheidungen zu reflektieren. So steht auch die Aktualität in Bezug auf Branchentrends und aufkommende Technologien im Fokus, um mögliche Veränderungen am Markt vorherzusehen und darauf vorbereitet zu sein. Indem wir uns auf die Zukunft konzentrieren, wollen wir ein nachhaltiges und erfolgreiches Unternehmen schaffen, das allen Herausforderungen gewachsen ist und auch für unsere Mandanten wirtschaftlich agierert.",
          },
          {
            headline: "WERTSCHÄTZUNG",
            text: "In unserer Steuerberatungskanzlei erkennen und schätzen wir die Einsätze jedes einzelnen unserer Kollegen. Wir glauben, dass eine Kultur der Wertschätzung für den Aufbau eines positiven und unterstützenden Arbeitsumfelds unerlässlich ist und sind bemüht, unsere Wertschätzung durch regelmäßige Anerkennung und Feedback zu zeigen. Darüber hinaus bieten wir Möglichkeiten zur beruflichen Weiterentwicklung sowie wettbewerbsfähige Sozialleistungen und Vergütungen, um unsere Wertschätzung für die Arbeit und das Engagement unserer Kollegen zu zeigen. Durch die Förderung einer Kultur der Wertschätzung wollen wir ein Gefühl der Zugehörigkeit und Loyalität unter unseren Teammitgliedern fördern und eine positive und lohnende Arbeitserfahrung für alle schaffen.",
          },
          {
            headline: "VERTRAUEN",
            text: "Vertrauen ist ein grundlegender Wert für uns. Wir sind davon überzeugt, dass Vertrauen für den Aufbau starker Beziehungen zu unseren Mandanten und Kollegen sowie für die Schaffung eines positiven und produktiven Arbeitsumfelds unerlässlich ist. Wir bemühen uns, das Vertrauen unserer Mandanten zu gewinnen, indem wir qualitativ hochwertige Arbeit leisten, ethische Standards einhalten und Vertraulichkeit wahren. Wir bemühen uns auch, das Vertrauen unter unseren Kollegen zu fördern, indem wir transparent, ehrlich und zuverlässig sind und einen sicheren und integrativen Arbeitsplatz schaffen, an dem jeder mit Respekt und Professionalität behandelt wird. Durch den Aufbau von Vertrauen wollen wir eine Grundlage des gegenseitigen Respekts und der Zusammenarbeit schaffen, die es uns ermöglicht, effektiv zusammenzuarbeiten und unsere gemeinsamen Ziele zu erreichen.",
          },
          {
            headline: "CROSS-BORDERS",
            text: "In unserem Unternehmen schätzen wir die Vielfalt der Perspektiven und Erfahrungen, die unsere Kollegen mitbringen. Wir sind davon überzeugt, dass das Überschreiten mentaler und geografischer Grenzen - sei es durch die Zusammenarbeit mit Kollegen aus anderen Kulturkreisen, das Denken über den Tellerrand oder die Arbeit mit Mandanten an anderen Standorten - uns hilft, unseren Horizont zu erweitern und Herausforderungen auf neue und innovative Weise anzugehen. Wir ermutigen unsere Kolleginnen und Kollegen, sich die Vielfalt zu eigen zu machen und offen für neue Ideen und Arbeitsweisen zu sein, und wir unterstützen sie dabei, sich die Fähigkeiten und das Wissen anzueignen, die für eine effektive grenzüberschreitende Arbeit erforderlich sind. Indem wir mentale und geografische Grenzen überschreiten, wollen wir ein starkes, globales Netzwerk von Fachwissen aufbauen, das uns hilft, unsere Kunden besser zu bedienen und an der Spitze der Branche zu bleiben.",
          },
          {
            headline: "TEAM",
            text: "In unserer Gesellschaft wissen wir, dass unsere Kollegen unser wertvollstes Potenzial sind. Wir sind davon überzeugt, dass die Arbeit im Team für das Erreichen unserer gemeinsamen Ziele und die Erbringung qualitativ hochwertiger Dienstleistungen für unsere Mandanten unerlässlich ist. Wir bemühen uns, ein unterstützendes und kooperatives Arbeitsumfeld zu schaffen, in dem Kollegen Ideen, Wissen und Ressourcen austauschen und gemeinsam an der Lösung komplexer Probleme arbeiten können. Wir legen Wert auf offene Kommunikation und Vertrauen zwischen den Teammitgliedern und ermutigen jeden, mit seinen Fähigkeiten und seinem Fachwissen zum Erfolg des Teams beizutragen. Durch die Förderung einer starken Teamkultur wollen wir eine positive und wertvolle Arbeitserfahrung für alle unsere Kollegen schaffen.",
          },
          {
            headline: "ZIELORIENTIERUNG",
            text: "Klare und messbare Ziele setzen und erreichen. Wir sind davon überzeugt, dass Zielorientierung eine wesentliche Voraussetzung für den Erfolg und die Maximierung der Wirkung unserer Arbeit ist. Wir ermutigen unsere Kollegen, proaktiv und lösungsorientiert an ihre Arbeit heranzugehen und sich darauf zu konzentrieren, Ergebnisse zu liefern, die den Bedürfnissen unserer Mandanten entsprechen und zum Gesamterfolg der Kanzlei beitragen. Außerdem unterstützen wir unsere Kollegen bei der Entwicklung der Fähigkeiten und Kenntnisse, die sie benötigen, um ihre eigenen persönlichen und beruflichen Ziele zu setzen und zu erreichen. Indem wir die Zielorientierung in den Vordergrund stellen, wollen wir eine Kultur der Verantwortlichkeit und Exzellenz schaffen, die uns hilft, unser volles Potenzial auszuschöpfen und unseren Kunden einen Mehrwert zu bieten.",
          },
        ],
      },
    },
    people: {
      headline: "Unsere Geschäftsleitung",
      subHeadline:
        "Gemeinsam mit Ihrem Team verkörpern die drei Geschäftsführer die Werte und Ziele der ETL Protax.",
      list: [
        {
          firstname: "Sascha",
          lastname: "Brömel",
          title: "Steuerberater",
          text: null,
          mail: "Sascha.Broemel@etl-protax.de",
          isGF: true,
        },
        {
          firstname: "Judith",
          lastname: "Liebler",
          title: "Wirtschaftsprüferin • Steuerberaterin",
          text: null,
          mail: "Judith.Liebler@etl-protax.de",
          isGF: true,
        },
        {
          firstname: "Christian",
          lastname: "Hoffmann",
          title: "Steuerberater",
          text: null,
          mail: "Christian.Hoffmann@etl-protax.de",
          isGF: true,
        },
        {
          firstname: "Verena",
          lastname: "Stork",
          title: "Personal",
          text: null,
          mail: "Verena.Stork@etl-protax.de",
          isGF: false,
        },
      ],
    },
    gallery: {
      headline: "Galerie",
    },
  },

  career: {
    boxes: [
      {
        headline: "GEMEINSAM",
        text: "Der Zusammenhalt bei ETL Protax ist unerlässlich,um unsere Ziele zu erreichen und unseren Kunden ein bestmögliches Ergebnis zu bieten. So können wir uns gegenseitig unterstützen, eine starke Grundlage für den Erfolg schaffen und sicherstellen, dass wir die Bedürfnisse und Erwartungen unserer Kunden erfüllen.",
      },
      {
        headline: "FLEXIBEL",
        text: "ETL PROTAX legt Wert auf die Vereinbarkeit von Beruf und Privatleben. Wir wissen, dass jede Person individuelle Bedürfnisse und Umstände hat, denen wir gerecht werden möchten. Diese Flexibilität gibt unseren Kollegen die Freiheit, ihr Arbeits- und Privatleben so zu gestalten, wie es für sie am besten ist und zu größtmöglicher Zufriedenheit beiträgt.",
      },
      {
        headline: "INNOVATIV",
        text: "Innovation und digitales Denken sind wichtige Erfolgsfaktoren bei ETL PROTAX. Indem wir über digitale Tools und Trends auf dem Laufenden bleiben, können wir Innovationen prüfen und integrieren. So sind wir der Zeit immer einen Schritt voraus und können unsere Arbeitsabläufe immer effizienter gestalten.",
      },
    ],
    reasons: {
      cta: "Bewirb dich jetzt",
      headline1: "8 GRÜNDE TEAMMITGLIED",
      headline2: "BEI ETL PROTAX ZU WERDEN",
      pointTextToggle: {
        open: "mehr",
        close: "weniger",
      },
      points: [
        {
          headline: "FLEXIBILITÄT",
          text: "Bei uns bist du flexibel in puncto Arbeitszeiten und -ort. Wir bieten dir dazu die technischen und organisatorischen Möglichkeiten.",
        },
        {
          headline: "WORK & TRAVEL",
          text: "Jeder Mitarbeiter bekommt die Chance bis zu 2 Wochen aus dem Ausland zu arbeiten. Aktuell planen wir für unsere Teams das Arbeiten im Ausland zu ermöglichen.",
        },
        {
          headline: "FAMILIE",
          text: "Uns ist die Vereinbarkeit von Familie und Beruf sehr wichtig. Dazu zählt auch Kinder, Pflegebedürftige und Tiere in deinen Alltag einzubinden.",
        },
        {
          headline: "HEALTH FOR ALL",
          text: "Wir fördern deine Gesundheit mit einem jährlichen Budget von 600€, unterstützt durch unseren Partner Health for All und das steuer- und sozialversicherungsfrei.",
        },
        {
          headline: "SICHERER ARBEITSPLATZ",
          text: "Du bist Teil eines innovativen und zukunftsfähigen Unternehmens innerhalb einer krisensicheren Branche.",
        },
        {
          headline: "WIR HÖREN DIR ZU",
          text: "Bei uns ist jeder gleich wichtig und kann sich ohne Hemmungen einbringen. Wir fördern deine Stärken und akzeptieren deine Schwächen.",
        },
        {
          headline: "DEINE AUFGABEN",
          text: "Spannende Aufgaben aus allen steuerl. und betriebswirtschaftl. Bereichen. Wir gehen auf deine Wünsche ein und falls du mal überfordert bist stehen wir hinter dir.",
        },
        {
          headline: "LAST BUT NOT LEAST",
          text: "Getränke, die du wirklich magst.",
        },
      ],
    },
    banner: {
      line1: "“Wo die Bedürfnisse der Welt mit deinen Talenten",
      line2: "zusammentreffen, dort liegt deine Berufung.” Aristoteles",
    },
    openPositions: {
      headline: "BEWIRB DICH JETZT",
      tasks: "DAS WÜNSCHEN WIR UNS VON IHNEN:",
      expectations: "DAS IST UNS WICHTIG:",
      offerings: "DAS VERSPRECHEN WIR IHNEN:",
      form: {
        entryDate: "Wunsch-Eintrittsdatum",
        dragAndDropTitle: "Drag & Drop",
        dragAndDropHint:
          "Eigene Dokumente hier hochladen (Lebenslauf, Zeugnisse, o.ä.)",
        maxFileSizeExceededToast:
          "Ihre Datei überschreitet die zulässige Größe von 50 MB.",
        cta: "Bewerbung senden",
        error: "Beim Versand Ihrer Bewerbung ist ein Fehler aufgetreten.",
        success:
          "Wir haben Ihre Bewerbung erhalten und melden uns schnellstmöglich.",
      },
      positions: [
        {
          title: "INITIATIVBEWERBUNG (M/W/D)",
          description: [
            "Wir sind eine regional verwurzelte, überregional und international agierende Steuerberatungsgesellschaft mit den Schwerpunkten Steuerberatung und Unternehmensberatung. Wir handeln zukunftsorientiert und beraten unsere Mandanten ganzheitlich. Unsere Mitarbeiter erklären Steuern, bilanzieren, prüfen, beraten, recherchieren, entscheiden und tragen Verantwortung – immer unterstützt von unserem aufgeschlossenen Geschäftsführer-Team.",
          ],
        },
        {
          title: "STEUERFACHWIRTE/BILANZBUCHHALTER (M/W/D)",
          description: [
            "Wir sind eine regional verwurzelte, überregional und international agierende Steuerberatungsgesellschaft mit den Schwerpunkten Steuerberatung und Unternehmensberatung. Wir handeln zukunftsorientiert und beraten unsere Mandanten ganzheitlich. Unsere Mitarbeiter erklären Steuern, bilanzieren, prüfen, beraten, recherchieren, entscheiden und tragen Verantwortung – immer unterstützt von unserem aufgeschlossenen Geschäftsführer-Team.",
            "Wir fokussieren uns auf mittelständische Unternehmen und Freiberufler die wir proaktiv an unseren Standorten in Aschaffenburg, Nilkheim und Miltenberg betreuen.",
            "Wir suchen Unterstützung für unsere Teams in Aschaffenburg und Miltenberg zum nächstmöglichen Zeitpunkt.",
          ],
          tasks: [
            "Sie besitzen eine Weiterbildung zum Steuerfachwirt oder Bilanzbuchhalter (m/w/d).",
            "Sie haben gute MS-Office-Kenntnisse, DATEV-Kenntnisse und arbeiten gerne digital.",
            "Sie erstellen Monats- und Quartalsabschlüsse sowie betriebswirtschaftliche Auswertungen.",
            "Sie sind ein Teamplayer, vertreten Ihre Meinung, sind dynamisch und haben Lust, sich mit Begeisterung voll einzubringen.",
            "Sie betreuen nationale und teilweise internationale Unternehmen.",
            "Sie haben Gelegenheit, in Zusammenarbeit mit den Berufsträgern, unsere nationalen und internationalen Mandanten betriebswirtschaftlich und steuerlich.",
          ],
          offerings: [
            "Bei uns fühlen Sie sich von Beginn an wohl, wir lachen miteinander, schätzen uns und feiern gemeinsam unsere Erfolge.",
            "Sie erwartet eine wertschätzende und kollegiale Zusammenarbeit in einem motivierten Team. Wir haben eine offene Unternehmenskultur mit flacher Hierarchie, in der Ihre Ideen geschätzt werden.",
            "Wir freuen uns, wenn Sie unsere Kanzlei mitgestalten, bieten stets Seminare zur Weiterbildung und sind offen für weitere Fortbildungen.",
            "Ihre Work-Life-Balance ist uns wirklich wichtig, deshalb gibt es bei uns flexible Arbeitszeitmodelle, die an Ihre Bedürfnisse angepasst werden können.",
            "Gerne unterstützen und fördern wir Sie individuell bei Ihrer weiteren beruflichen Entwicklung.",
          ],
          expectations:
            "Wir haben einen hohen Anspruch an unsere Mandatsbetreuung. Offenheit, Freundlichkeit und Verantwortungsbewusstsein sollten daher zu Ihren Grundeigenschaften gehören. Der Umgang mit den unterschiedlichsten Menschen bereitet Ihnen Freude.",
          applicationLink:
            "https://etlgruppe.career.softgarden.de/application/?jobId=46072118",
        },
        {
          title: "STEUERFACHANGESTELLTE (M/W/D)",
          description: [
            "Wir sind eine regional verwurzelte, überregional und international agierende Steuerberatungsgesellschaft mit den Schwerpunkten Steuerberatung und Unternehmensberatung. Wir handeln zukunftsorientiert und beraten unsere Mandanten ganzheitlich. Unsere Mitarbeiter erklären Steuern, bilanzieren, prüfen, beraten, recherchieren, entscheiden und tragen Verantwortung – immer unterstützt von unserem aufgeschlossenen Geschäftsführer-Team.",
            "Wir fokussieren uns auf mittelständische Unternehmen und Freiberufler die wir proaktiv an unseren Standorten in Aschaffenburg, Nilkheim und Miltenberg betreuen.",
            "Wir suchen Unterstützung für unsere Teams in Aschaffenburg und Miltenberg zum nächstmöglichen Zeitpunkt.",
          ],
          tasks: [
            "Sie besitzen eine abgeschlossene Berufsausbildung als Steuerfachangestellte/r oder eine vergleichbare Qualifikation.",
            "Sie verfügen über Berufserfahrung und fundierte Kenntnisse im Steuerrecht und in der Bilanzierung nach HGB.",
            "Sie haben gute MS-Office-Kenntnisse, optimalerweise DATEV-Kenntnisse und arbeiten gerne digital.",
            "Sie sind ein Teamplayer, vertreten Ihre Meinung, sind dynamisch und haben Lust, sich mit Begeisterung voll einzubringen.",
            "Sie erstellen Monats- und Jahresabschlüsse, Einnahmenüberschussrechnungen sowie betriebliche und private Steuererklärungen. Bei Interesse unterstützen Sie uns gerne in der Lohnbuchhaltung.",
            "Sie betreuen das Rechnungswesen inklusive der Finanz- und Anlagenbuchhaltung nationaler und teilweise internationaler Unternehmen.",
            "Sie haben Gelegenheit, in Zusammenarbeit mit den Berufsträgern, die Mandanten betriebswirtschaftlich und steuerlich zu beraten.",
            "Korrespondenz mit Mandanten und Finanzämtern gehört zu Ihren täglichen Aufgaben. ",
          ],
          offerings: [
            "Bei uns fühlen Sie sich von Beginn an wohl, wir lachen miteinander, schätzen uns und feiern gemeinsam unsere Erfolge.",
            "Sie erwartet eine wertschätzende und kollegiale Zusammenarbeit in einem motivierten Team. Wir haben eine offene Unternehmenskultur mit flacher Hierarchie, in der Ihre Ideen geschätzt werden.",
            "Wir freuen uns, wenn Sie unsere Kanzlei mitgestalten, bieten stets Seminare zur Weiterbildung und sind offen für weitere Fortbildungen.",
            "Ihre Work-Life-Balance ist uns wirklich wichtig, deshalb gibt es bei uns flexible Arbeitszeitmodelle, die an Ihre Bedürfnisse angepasst werden können.",
            "Gerne unterstützen und fördern wir Sie individuell bei Ihrer weiteren beruflichen Entwicklung.",
          ],
          expectations:
            "Wir haben einen hohen Anspruch an unsere Mandatsbetreuung. Offenheit, Freundlichkeit und Verantwortungsbewusstsein sollten daher zu Ihren Grundeigenschaften gehören. Der Umgang mit den unterschiedlichsten Menschen bereitet Ihnen Freude.",
        },
        {
          title: "LOHNBUCHHALTER (M/W/D)",
          description: [
            "Wir sind eine regional verwurzelte, überregional und international agierende Steuerberatungsgesellschaft mit den Schwerpunkten Steuerberatung und Unternehmensberatung. Wir handeln zukunftsorientiert und beraten unsere Mandanten ganzheitlich. Unsere Mitarbeiter erklären Steuern, bilanzieren, prüfen, beraten, recherchieren, entscheiden und tragen Verantwortung – immer unterstützt von unserem aufgeschlossenen Geschäftsführer-Team.",
            "Wir fokussieren uns auf mittelständische Unternehmen und Freiberufler die wir proaktiv an unseren Standorten in Aschaffenburg, Nilkheim und Miltenberg betreuen.",
            "Wir suchen Unterstützung für unsere Teams in Aschaffenburg und Miltenberg zum nächstmöglichen Zeitpunkt.",
          ],
          tasks: [
            "Sie besitzen eine kaufmännische Berufsausbildung mit Berufserfahrung in der Entgeltabrechnung, einschließlich Baulohn.",
            "Sie verfügen über fundierte Kenntnisse im Bereich Lohnsteuer- und Sozialversicherungsrecht.",
            "Sie haben gute MS-Office-Kenntnisse, optimalerweise im Lohnabrechnungsprogramm der DATEV und arbeiten gerne digital.",
            "Sie sind ein Teamplayer, vertreten Ihre Meinung, sind dynamisch und haben Lust, sich mit Begeisterung voll einzubringen.",
            "Sie begleiten die Prüfungen von Einrichtungen wie Sozialversicherungen, Finanzamt und SOKA.",
            "Korrespondenz mit Mandanten und Finanzämtern gehört zu Ihren täglichen Aufgaben. ",
          ],
          offerings: [
            "Bei uns fühlen Sie sich von Beginn an wohl, wir lachen miteinander, schätzen uns und feiern gemeinsam unsere Erfolge.",
            "Sie erwartet eine wertschätzende und kollegiale Zusammenarbeit in einem motivierten Team. Wir haben eine offene Unternehmenskultur mit flacher Hierarchie, in der Ihre Ideen geschätzt werden.",
            "Wir freuen uns, wenn Sie unsere Kanzlei mitgestalten, bieten stets Seminare zur Weiterbildung und sind offen für weitere Fortbildungen.",
            "Ihre Work-Life-Balance ist uns wirklich wichtig, deshalb gibt es bei uns flexible Arbeitszeitmodelle, die an Ihre Bedürfnisse angepasst werden können.",
            "Gerne unterstützen und fördern wir Sie individuell bei Ihrer weiteren beruflichen Entwicklung.",
          ],
          expectations:
            "Wir haben einen hohen Anspruch an unsere Mandatsbetreuung. Offenheit, Freundlichkeit und Verantwortungsbewusstsein sollten daher zu Ihren Grundeigenschaften gehören. Der Umgang mit den unterschiedlichsten Menschen bereitet Ihnen Freude.",
        },
      ],
    },
  },

  services: {
    submenu: {
      items: ["STEUERBERATUNG", "RESTRUKTRIERUNG", "UNTERNEHMERBERATUNG"],
    },
    textImage: {
      sectionsCTA: "Weitere Infos >",
      sections: [
        {
          headline: "StartUp",
          text: "Mit dem Start eines Unternehmens kommen viele Herausforderungen auf Dich zu. ETL PROTAX steht Dir von Anfang an zur Seite und unterstützt Dich bei allen konkreten Entscheidungen. Wir beraten Dich der Auswahl der besten Rechtsform und Finanzierungsmöglichkeiten, um Deine Geschäftsidee zu realisieren. Außerdem unterstützen wir Dich bei Deinen ersten Kontakten mit dem Finanzamt und beim Aufbau von digitalen und schlanken Buchhaltungsprozessen. Wir begleiten Dich Schritt für Schritt auf dem Weg zum erfolgreichen Unternehmer und stehen Dir jederzeit mit unserem Fachwissen und unserer Erfahrung zur Verfügung.",
        },
        {
          headline: "Wachstum",
          text: "Wir helfen Dir dabei, die richtigen Schritte zu gehen, um zu wachsen und Dein Unternehmen zu stärken. Unser Team hat umfassende Erfahrung in den Bereichen Finanzierung und Steueroptimierung, die wir nutzen, um Dir individuelle Lösungen anzubieten. Wir beraten Dich auch bei der Expansion Deines Unternehmens, um neue Märkte zu erschließen und können dabei auch auf unser internationales ETL Global Netzwerk zurückgreifen. Arbeiten wir daran, Deine Ziele zu erreichen und Dein Unternehmen auf ein höheres Niveau zu bringen.",
        },
        {
          headline: "Stabilität",
          text: "ETL PROTAX hilft Unternehmen dabei, eine solide Finanzgrundlage aufzubauen und ihre Finanzen effizient zu verwalten. Durch die Optimierung von Finanz- und Lohnbuchhaltung sowie Finanzmanagement-Systemen sparen Unternehmen Zeit und Ressourcen. Darüber hinaus berät unser Team bei steuerlichen Angelegenheiten und sorgt dafür, dass Unternehmen ihre Gewinne optimal nutzen können. Eine solide Finanzgrundlage ist unerlässlich für den schnellen Erfolg eines Unternehmens und ETL PROTAX ist hierbei ein zuverlässiger Partner.",
        },
        {
          headline: "Sättigung",
          text: "Diese Phase kann für viele Unternehmen eine Herausforderung darstellen, da sie sich in einem Markt befinden, in dem das Wachstum stagniert oder sogar zurückgeht. In solchen Situationen ist es wichtig, schnell zu handeln und neue Geschäftsmöglichkeiten zu erschließen. Hierbei kann ETL PROTAX eine wertvolle Unterstützung sein. Unser Team hat umfangreiche Erfahrungen im Bereich Unternehmensberatung und kann Dir bei der Identifizierung von neuen Geschäftsmöglichkeiten helfen. Außerdem unterstützen wir Dich bei der Überarbeitung Deiner Geschäftsstrategie und bei der Restrukturierung Deines Unternehmens. Ziel ist es, Dir dabei zu helfen, Deine Wettbewerbsfähigkeit zu steigern und Dein Unternehmen auf eine solide Finanzbasis zu stellen, damit Du auch in der Zukunft erfolgreich bleiben kannst.",
        },
        {
          headline: "Erneuerung",
          text: "Unser Team berät Dich bei der Überarbeitung von Geschäftsstrategien und Prozessen, damit das Unternehmen auch in der Zukunft sicher bleibt. Durch die Analyse des Marktes und die Bewertung von Trends können wir gemeinsam neue Geschäftsmöglichkeiten identifizieren und nutzen. Die Implementierung innovativer Technologien und Methoden kann dabei helfen, Prozesse zu optimieren und Zeit- und Kosteneinsparungen zu erzielen. Unser Ziel ist es, Dir zu helfen, Dein Unternehmen auf die nächste Stufe zu bringen. Wir arbeiten eng mit Dir zusammen, um sicherzustellen, dass alle Veränderungen reibungslos und effektiv umgesetzt werden, damit Dein Unternehmen auch in Zukunft erfolgreich bleibt.",
        },
        {
          headline: "Umstrukturierung",
          text: "Wir helfen Dir dabei, Deine Finanzen zu stabilisieren und Deine Geschäftsabläufe so zu optimieren, dass Du in Zukunft erfolgreich agieren kannst. Unser Team analysiert Deine aktuelle Situation und erstellt einen Maßnahmenplan, der auf Deine individuellen Bedürfnisse zugeschnitten ist. Gemeinsam mit Dir setzen wir die notwendigen Schritte um, damit Du auch in schwierigen Zeiten erfolgreich bleibst. Wir stehen Dir bei all Deinen steuerlichen und wirtschaftlichen Herausforderungen in der Umstrukturierungsphase zur Seite.",
        },
        {
          headline: "Nachfolge",
          text: "Unabhängig davon ob Du Vermögen innerhalb der Familie übertragen, oder Dein Unternehmen an externe Investoren verkaufen möchtest, begleiten wir Dich von Anfang an bei der Planung und Ausarbeitung eines optimalen Nachfolgeprozesses. Dazu gehören insbesondere die steuerliche Strukturierung, Unternehmensbewertung sowie vertragliche Ausgestaltung. Eine unserer Stärken liegt darin, notwendiges Verständnis für alle beteiligten Parteien aufzubringen und damit ein bestmögliches Ergebnis zu erzielen.",
        },
      ],
    },
  },

  locations: {
    box: {
      headline: "2 STANDORTE - EIN TEAM",
      text: "Unser Team ist an den Standorten Aschaffenburg und Miltenberg vertreten und profitiert von den Vorteilen dieser verschiedenen Standorte. Diese Verteilung ermöglicht uns eine größere Reichweite, lokale Expertise und eine optimale Nutzung von Ressourcen. Dadurch können wir unseren Mandanten an jedem Standort einen erstklassigen Service bieten und von einem vielfältigen Wissensaustausch innerhalb unseres Teams profitieren.",
    },
    boxes: {
      items: [
        {
          location: "ASCHAFFENBURG",
          name: "ETL Protax GmbH Steuerberatungsgesellschaft",
          street: "Magnolienweg 3",
          city: "63741 Aschaffenburg",
          mail: "info@etl-protax.de",
          phone: ["+49 (0) 9371 / 99800", "+49 (0) 6021 / 44480"],
          coordinates: { lat: 49.964835, lng: 9.1291819 },
        },
        {
          location: "MILTENBERG",
          name: "ETL Protax GmbH Steuerberatungsgesellschaft",
          street: "Im Bruch 18",
          city: "63897 Miltenberg",
          mail: "info@etl-protax.de",
          phone: "+49 (0) 9371 / 99800",
          coordinates: { lat: 49.7059131, lng: 9.2222457 },
        },
      ],
    },
    continents: {
      headlineFirst: "WE ARE ",
      headlineSecond: "GLOBAL",
      items: [
        {
          label: "AFRICA",
          url: "https://www.etl-global.com/africa/",
        },
        { label: "AMERICA", url: "https://www.etl-global.com/america/" },
        { label: "ASIA", url: "https://www.etl-global.com/asia-pacific/" },
        { label: "EUROPE", url: "https://www.etl-global.com/europe/" },
        {
          label: "OCEANIA",
          url: "https://www.etl-global.com/australia-oceania/",
        },
      ],
      highlightedCountries: [
        "ad",
        "au",
        "at",
        "be",
        "kh",
        "cn",
        "cy",
        "cz",
        "dk",
        "ec",
        "ee",
        "fi",
        "fr",
        "de",
        "gb",
        "us",
        "gr",
        "hk",
        "hu",
        "in",
        "id",
        "it",
        "jp",
        "ke",
        "la",
        "lt",
        "lu",
        "my",
        "mm",
        "nl",
        "nz",
        "ng",
        "ph",
        "pl",
        "pt",
        "ro",
        "sg",
        "sk",
        "za",
        "kr",
        "es",
        "se",
        "ch",
        "th",
        "tw",
        "tr",
        "ua",
        "ae",
        "vn",
        "bd",
        "mn",
        "rs",
        "hr",
        "pe",
        "bg",
        "ca",
        "gt",
        "il",
        "mu",
        "br",
        "ie",
      ],
    },
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  genericInfo: {
    name: "ETL Protax GmbH",
    nameType: "Steuerberatungsgesellschaft",
    street: "Im Bruch 18",
    city: "63897 Miltenberg",
    mail: "info@etl-protax.de",
    phone: "+49 (0) 9371 / 99800",
    phone2: "+49 (0) 6021 / 44480",
  },

  generic: {
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    name: "Name",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Telefon",
    fax: "Fax",
    mail: "Email",
    password: "Passwort",
    message: "Nachricht",
    close: "Schließen",
    confirm: "Bestätigen",
  },

  login: {
    headline: "Login",
    cta: "Einloggen",
    error: "E-Mail Adresse oder Passwort falsch.",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
