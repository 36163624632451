import {
  CarouselWrapper,
  StyledGallery,
  Headline,
  ImageWrapper,
  Image,
} from "./Gallery.Styled";
import gallery1 from "../../../assets/image/etl-protax/gallery/1.jpg";
import gallery2 from "../../../assets/image/etl-protax/gallery/2.png";
import gallery3 from "../../../assets/image/etl-protax/gallery/3.png";
import gallery4 from "../../../assets/image/etl-protax/gallery/4.jpg";
import { getUUID } from "../../../utility";
import Carousel from "../../SubComponents/Carousel/Carousel";
import { useSelector } from "react-redux";
import props from "../../../redux/props";

const Gallery = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const images = [gallery1, gallery2, gallery3, gallery4];

  return (
    <StyledGallery>
      <Headline>{translation.etlProtax.gallery.headline}</Headline>
      <CarouselWrapper>
        <Carousel
          autoplay={false}
          autoplay_speed={5000}
          disable_box_shadow={true}
        >
          {images.map((img) => (
            <ImageWrapper key={getUUID()}>
              <Image src={img} />
            </ImageWrapper>
          ))}
        </Carousel>
      </CarouselWrapper>
    </StyledGallery>
  );
};

export default Gallery;
