import styled from "styled-components";

export const StyledContact = styled.div``;

export const Bottom = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 50px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: initial;
  }
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  margin-top: 25px;
`;
export const NameType = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`;
export const Split = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  justify-content: start;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 25px;
  }
`;
export const Item = styled.div`
  width: calc(100% / 3 - 50px * 2 / 3);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Value = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.secondary};
  display: block;
`;

export const Street = styled(Value)``;
export const City = styled(Value)``;
export const Mail = styled(Value)``;
export const Phone = styled(Value)``;

export const FormWrapper = styled.div`
  position: absolute;
  right: 15vw;
  width: fit-content;
  bottom: 200px;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: initial;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
`;

export const Hero = styled.div`
  height: 80vh;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-position: center center;
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 50vh;
  }
`;

export const GenericInfo = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 15px;
  }
`;
