const black = "rgb(77,77,77)";
const white = "#fafbfd";
const lightgray = "#cdcdcd";

const theme = {
  font: {
    size: {
      sm: "16px",
      md: "18px",
      lg: "25px",
      xlg: "40px",
    },
  },
  color: {
    font: {
      primary: black,
      secondary: white,
      thirdary: "rgb(77,77,77)",
      hint: lightgray,
    },
    background: {
      primary: "rgb(122, 122, 122)",
      secondary: black,
      thirdary: lightgray,
      fourth: "rgb(77,77,77)",
      fifth: "rgb(242,242,242)",
      red: "rgb(208,47,38)",
    },
    border: {
      primary: black,
      secondary: white,
    },
  },
  breakpoint: {
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  border: {
    radius: "5px",
  },
  header: {
    background: "rgb(179, 179, 179)",
    font: "rgb(77,77,77)",
    height: "60px",
  },
  shadow: "2px 2px 16px -1px rgba(0,0,0,0.48)",
};

const adjustedComponentStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: ``,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: `height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: white;
      border-radius: 0px;
    `,
    title: `text-transform: uppercase;
      color: ${theme.color.font.primary};
      font-size: ${theme.font.size.lg};
      font-family: bold;
    `,
    hint: `
      font-style: unset;
    `,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: ``,
    select: ``,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: `
      background-color: white;
      border-radius: 0px;
      border: none;
    `,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: ``,
    background: ``,
    core: ``,
    header: ``,
    title: ``,
    close: ``,
    content: ``,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: ``,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
};

const buildTheme = { ...theme, components: adjustedComponentStyling };

export default buildTheme;
