import styled from "styled-components";

export const StyledBoxes = styled.div`
  transform: translateY(-50px);
  display: flex;
  gap: 15px;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  padding: 30px;
  background-color: ${({ theme, active }) =>
    active ? "rgba(208, 47, 38)" : theme.color.background.fourth};
  cursor: pointer;
  width: calc(100% / 3);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Item = styled.div`
  ${({ margin }) => margin && `margin-top: 15px;`};
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const ItemLink = styled.a`
  ${({ margin }) => margin && `margin-top: 15px;`};
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: none;
`;
