import {
  StyledTextImageSplit,
  TextSectionWrapper,
  TextSection,
  Flag,
  Headline,
  Text,
  CTA,
} from "./TextImageSplit.Styled";
import flagWhiteFilled from "../../../assets/image/flag-white-filled.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { getUUID } from "../../../utility";

const TextImageSplit = ({ id, index, data, image, revert }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledTextImageSplit revert={revert} bg={image}>
      <TextSectionWrapper revert={revert}>
        <TextSection>
          <Flag src={flagWhiteFilled} />
          <Headline key={getUUID()}>
            {`${index + 1}. `}
            {data.headline}
          </Headline>
          <Text>{data.text}</Text>
          <CTA>{translation.services.textImage.sectionsCTA}</CTA>
        </TextSection>
      </TextSectionWrapper>
    </StyledTextImageSplit>
  );
};

export default TextImageSplit;
