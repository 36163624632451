import { useSelector } from "react-redux";
import props from "../../../redux/props";
import Flag from "../../SubComponents/Flag/Flag";
import {
  StyledTextBlock,
  ContentWrapper,
  Headline,
  Text,
} from "./TextBlock.Styled";

const TextBlock = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledTextBlock>
      <ContentWrapper>
        <Headline>{translation.etlProtax.textBlock.headline}</Headline>
        <Flag width={"30px"} />
        <Text>{translation.etlProtax.textBlock.text}</Text>
      </ContentWrapper>
    </StyledTextBlock>
  );
};

export default TextBlock;
