import styled from "styled-components";

export const StyledContinents = styled.div`
  margin-top: 100px;
  padding-bottom: 100px;
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 30px;
  text-align: center;
`;

export const HeadlineLink = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.background.red};
  padding: 0 5px;
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
  flex-wrap: wrap;
`;

export const Item = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  background-color: ${({ theme }) => theme.color.background.red};
  width: 15%;
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: fit-content;
    padding: 5px 10px;
  }
`;

export const SVGMapWrapper = styled.div`
  margin: 75px 0;
  .svg-map {
    width: 100%;
    height: auto;
    stroke: #666;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;

    &__location {
      fill: rgb(179, 179, 179);
      cursor: default;
      &:hover,
      &:focus {
        outline: 0;
      }

      &[aria-checked="true"] {
        fill: rgb(208, 47, 38);
        cursor: pointer;
        &:hover {
          fill: rgb(77, 77, 77);
        }
      }
    }
  }
`;

export const CountryList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Country = styled.a`
  width: calc(100% / 6);
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2);
  }
`;
