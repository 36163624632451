import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledTextBlock = styled(Container)`
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    padding: 50px 0px 100px 0;
  }
`;

export const ContentWrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  font-family: bold;
  width: 100%;
`;
export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
`;
