import styled from "styled-components";
import { Container, Link } from "@think-internet/ui-components";

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  height: ${({ theme }) => theme.header.height};
  z-index: 999;
  top: 0;
  background-color: ${({ theme }) => theme.header.background};
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

export const Toggle = styled.img`
  cursor: pointer;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    height: 30px;
  }
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.header.font};
  display: flex;
  align-self: center;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const Pages = styled.div`
  display: flex;
  gap: 60px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const MobileNavigation = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.header.background};
  position: absolute;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.header.height});
  margin-top: ${({ theme }) => theme.header.height};
  top: 0px;
  left: 0px;
`;

export const StyledLegalToggle = styled.div`
  position: relative;
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const LegalPopup = styled.div`
  position: absolute;
  top: calc(100% + 25px);
  right: 0%;
  background-color: ${({ theme }) => theme.header.background};
  padding: 15px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadow};
  z-index: 999;
  box-sizing: content-box;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  a {
    display: block;
  }
`;

export const LegalTrigger = styled.div`
  color: ${({ theme }) => theme.header.font};
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .icon {
    font-size: ${({ theme }) => theme.font.size.lg};
    color: ${({ theme }) => theme.header.font};
  }
`;
