import styled from "styled-components";

export const StyledBanner = styled.div`
  background-image: url(${({ bg }) => bg});
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center center;
`;

export const TextWrapper = styled.div``;

export const Text = styled.div`
  text-align: center;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.lg};
  line-height: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`;
