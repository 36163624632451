import styled from "styled-components";
import flagImage from "../../../../assets/image/flag.svg";

export const StyledReason = styled.div`
  width: calc(25% - 25px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
export const Number = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: bold;
  font-size: ${({ theme }) => theme.font.size.lg};
  background-image: url(${flagImage});
  padding: 0px 1px 15px 15px;
  background-size: contain;
  background-repeat: no-repeat;
`;
export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: left;
  margin-bottom: 15px;
`;

export const TextToggle = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  cursor: pointer;
  display: inline;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
  ${({ show }) =>
    !show &&
    `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    white-space: normal;
  }
`;
