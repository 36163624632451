import styled from "styled-components";

export const StyledTextImageSplit = styled.div`
  position: relative;
  margin: 150px 0px;
  display: flex;
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: ${({ revert }) => (revert ? "right" : "left")};
  background-size: auto 100%;
  justify-content: ${({ revert }) => (revert ? "start" : "end")};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    margin: 50px 0px;
    background-image: none;
  }
`;

export const TextSectionWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 70%;
  margin: 75px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    margin: 0px;
  }
`;

export const TextSection = styled.div`
  text-align: center;
  padding: 50px;
  background-color: rgba(208, 47, 38, 0.85);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 25px 0px;
  }
`;

export const Flag = styled.img`
  width: 40px;
`;

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: bold;
`;
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  width: 80%;
  margin: 10px auto 0px auto;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    padding: 10px 15px 0px 15px;
    margin: 0px;
  }
`;
export const CTA = styled.a`
  background-color: rgb(179, 179, 179);
  color: ${({ theme }) => theme.color.font.secondary};
  display: inline-block;
  padding: 7.5px 15px;
  margin-top: 25px;
  font-size: ${({ theme }) => theme.font.size.sm};
  text-decoration: none;
`;
