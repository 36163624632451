import { Map } from "./GoogleMap.Styled";
import { useRef } from "react";
import { useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import style from "./style.js";
import { googleAPIKey } from "../../../../utility";
import activePin from "../../../../assets/image/locations/active-pin.png";
import inactivePin from "../../../../assets/image/locations/inactive-pin.png";

const MyMapComponent = ({ options, markers }) => {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      styles: style,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      ...options,
    });
    markers.forEach((marker) => {
      new window.google.maps.Marker({
        position: marker.coordinates,
        map,
        icon: {
          url: marker.active ? activePin : inactivePin,
          scaledSize: new window.google.maps.Size(37, 50),
        },
      });
    });
  });

  return <Map ref={ref} id="map" />;
};

const GoogleMap = ({ options, markers }) => {
  const render = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
  };
  return (
    <Wrapper apiKey={googleAPIKey} render={render}>
      <MyMapComponent options={options} markers={markers}></MyMapComponent>
    </Wrapper>
  );
};

export default GoogleMap;
