import styled from "styled-components";

export const StyledItemContent = styled.div``;

export const Prop = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Key = styled.div`
  width: 30%;
  font-family: bold;
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Value = styled.div`
  width: 70%;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const ApplyFormWrapper = styled.div`
  width: 80%;
  margin: 50px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`;

export const DescriptionItem = styled.div`
  margin-bottom: 30px;
`;

export const Enumeration = styled.ul`
  margin-left: 15px;
  li {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`;

export const CTA = styled.a`
  background-color: ${({ theme }) => theme.color.background.red};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 7.5px 25px;
  margin: 50px auto 75px;
  display: block;
  width: fit-content;
  text-decoration: none;
`;
