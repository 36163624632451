import { useSelector } from "react-redux";
import props from "../../../../redux/props";
import {
  StyledTemplate,
  Subtitle,
  Title,
  By,
  Others,
  CTA,
} from "./Template.Styled";
import { getUUID } from "../../../../utility";

const Template = ({ translationKey, href }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledTemplate>
      <Subtitle>{translation.home[translationKey].subtitle}</Subtitle>
      {translation.home[translationKey].title.map((t) => (
        <Title
          isSingle={translation.home[translationKey].title.length === 1}
          key={getUUID()}
        >
          {t}
        </Title>
      ))}
      <By>{translation.home[translationKey].by}</By>
      {translation.home[translationKey].others && (
        <Others>{translation.home[translationKey].others}</Others>
      )}
      <CTA href={href}>{translation.home[translationKey].cta}</CTA>
    </StyledTemplate>
  );
};

export default Template;
