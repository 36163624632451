import { useState } from "react";
import { getUUID } from "../../../../utility";
import { StyledPositions, Flex } from "./Positions.Styled";
import Item from "./Item/Item";
import React from "react";

const Positions = ({ items, className, initOpenIndex = null }) => {
  const [openIndex, setOpenIndex] = useState(initOpenIndex);

  const toggle = (index) => () =>
    setOpenIndex(openIndex === index ? null : index);

  if (!Array.isArray(items)) return <></>;
  return (
    <StyledPositions className={className}>
      <Flex>
        {items.map(({ title, content }, i) => (
          <Item
            open={openIndex === i}
            toggle={toggle(i)}
            title={title}
            content={content}
            key={getUUID()}
            id={i}
          />
        ))}
      </Flex>
    </StyledPositions>
  );
};

export default Positions;
