import styled from "styled-components";

export const StyledSlider = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Slide = styled.div`
  position: relative;
  z-index: 2;
  &.next,
  &.previous {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0.7);
    opacity: 0.5;
    z-index: 1;
  }
  &.next {
    right: 0;
  }
  &.previous {
    left: 0;
  }
`;

export const Navigator = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 3;
  cursor: pointer;
  &.next {
    right: 0;
  }
  &.previous {
    left: 0;
  }
`;
