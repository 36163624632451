import { Button, Form, Input, Textarea } from "@think-internet/ui-components";
import styled from "styled-components";
import bg from "../../../assets/image/contact/form/bg.png";

export const StyledContactForm = styled(Form)`
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 500px;
  padding: 125px 50px 50px 50px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100vw;
  }
`;

export const Flag = styled.img`
  position: absolute;
  top: -15px;
  left: 0px;
  width: 100px;
  margin-left: -50px;
  left: 50%;
`;

export const CustomInput = styled(Input)`
  font-size: 16px;
  background-color: black;
  border-radius: 0px;
  border: none;
  color: ${({ theme }) => theme.color.font.secondary};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.font.secondary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.font.secondary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.font.secondary};
  }
`;

export const CustomTextarea = styled(Textarea)`
  font-size: 16px;
  background-color: black;
  border-radius: 0px;
  border: none;
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 5px 10px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.font.secondary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.font.secondary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.font.secondary};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
`;
export const Label = styled.div`
  font-weight: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.background.red};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 5px 30px;
  border-radius: 0px;
  border: none;
  font-size: ${({ theme }) => theme.font.size.sm};
`;
