import styled from "styled-components";

export const StyledPeople = styled.div`
  padding: 50px 0;
  background-color: ${({ theme }) => theme.color.background.fifth};
  text-align: center;
`;

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: bold;
  text-transform: uppercase;
`;

export const SubHeadline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
  width: 70%;
  margin: 10px auto 15px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const List = styled.div`
  display: flex;
  gap: 100px;
  margin-top: 125px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Person = styled.div`
  width: 50%;
  position: relative;
  padding: 125px 15px 20px 15px;
  background-color: white;
  box-shadow: 2px 2px 9px 0px rgb(129 129 129 / 48%);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.hint};
`;

export const Image = styled.img`
  position: absolute;
  top: -75px;
  width: 150px;
  border-radius: 150px;
  left: 50%;
  margin-left: -75px;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin: 25px 0px;
`;

export const Value = styled.div`
  display: flex;
  gap: 15px;
  margin: 5px 0px;
`;

export const ValueText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
`;

export const ValueLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.sm};
  text-decoration: none;
  color: ${({ theme }) => theme.color.font.primary};
`;
