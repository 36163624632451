import {
  StyledBox,
  Float,
  InnerFloat,
  Headline,
  Split,
  Item,
  Image,
  Text,
} from "./Box.Styled";
import image from "../../../assets/image/locations/box.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";

const Box = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledBox>
      <Float>
        <InnerFloat>
          <Headline>{translation.locations.box.headline}</Headline>
          <Split>
            <Item>
              <Image src={image} />
            </Item>
            <Item>
              <Text>{translation.locations.box.text}</Text>
            </Item>
          </Split>
        </InnerFloat>
      </Float>
    </StyledBox>
  );
};

export default Box;
