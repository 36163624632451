import styled from "styled-components";

export const StyledCard = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.color.background.darkBlue};
  text-align: center;
`;

export const Background = styled.div`
  position: absolute;
  top: -20%;
  left: 0;
  width: 50%;
  height: 140%;

  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Content = styled.div`
  padding: 0 50px 0 50px;
  background-color: rgba(208, 47, 38, 0.85);
  width: 500px;
  height: 550px;
  display: inline-block;
  position: relative;
  margin-left: ${({ isActive }) => (isActive ? "150px" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Flag = styled.img`
  width: 60px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -30%);
  top: 0px;
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-family: "bold";
  text-align: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 25px;
`;
