import { Hero } from "@think-internet/ui-components";
import Layout from "../Layout/Layout";
import {
  StyledETLProtax,
  HeroContentContainer,
  HeroContentWrapper,
  Title,
  By,
  Others,
  Logo,
} from "./ETLProtax.Styled";
import heroImage from "../../assets/image/etl-protax/hero.webp";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Boxes from "./Boxes/Boxes";
import TextBlock from "./TextBlock/TextBlock";
import Goals from "./Goals/Goals";
import People from "./People/People";
import logo from "../../assets/image/logo.svg";
import Gallery from "./Gallery/Gallery";

const ETLProtax = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Layout authRequired={false}>
      <StyledETLProtax>
        <Hero img={heroImage}>
          <HeroContentContainer>
            <HeroContentWrapper>
              <Logo src={logo} />
              <Title>{translation.home.rise.title}</Title>
              <By>{translation.home.rise.by}</By>
              <Others>{translation.home.rise.others}</Others>
            </HeroContentWrapper>
          </HeroContentContainer>
        </Hero>
        <Boxes />
        <TextBlock />
        <Goals />
        <People />
        <Gallery />
      </StyledETLProtax>
    </Layout>
  );
};

export default ETLProtax;
