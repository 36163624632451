import styled from "styled-components";

export const StyledMap = styled.div``;

export const GoogleMapWrapper = styled.div`
  margin-top: 50px;
  height: 75vh;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 400px;
  }
`;
