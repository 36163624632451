import styled from "styled-components";

export const StyledItem = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
  ${({ theme }) => theme?.components?.accordion?.item?.item};
`;

export const Header = styled.div`
  background-color: ${({ theme, open }) =>
    open ? theme.color.background.red : theme.color.background.fifth};
  color: ${({ color, theme, open }) =>
    color || (open ? theme.color.font.secondary : theme.color.font.primary)};
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme?.components?.accordion?.item?.header};
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  ${({ theme }) => theme?.components?.accordion?.item?.text};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
    word-break: break-word;
  }
`;

export const OpenIcon = styled.div`
  position: absolute;
  font-size: ${({ theme }) => theme.font.size.xlg};
  font-weight: 100;
  width: 20px;
  text-align: center;
  top: 0px;
  height: 100%;
  right: 10px;
  display: flex;
  align-items: center;
  color: ${({ theme, open }) =>
    open ? theme.color.font.secondary : theme.color.font.primary};
  ${({ theme }) => theme?.components?.accordion?.item?.openicon};
`;

export const Icon = styled.img`
  height: 20px;
`;

export const Body = styled.div`
  padding: 5px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  color: ${({ theme }) => theme.color.font.primary};
  border-radius: ${({ theme }) => theme.border.radius};
  ${({ theme }) => theme?.components?.accordion?.item?.body};
`;
