import styled from "styled-components";

export const StyledGallery = styled.div`
  padding: 150px 0px 150px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  min-height: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px 50px 0px;
    min-height: auto;
  }
`;

export const CarouselWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  padding: 75px 50px;
  gap: 25px;
  box-sizing: content-box;
  height: 600px;
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    height: auto;
    padding: 50px 0px;
    margin: 100px 0;
  }
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-align: center;
  text-transform: uppercase;
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  max-width: 50vw;
  max-height: 500px;
`;
