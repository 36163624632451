import Layout from "../Layout/Layout";
import {
  StyledServices,
  HeroContentContainer,
  HeroContentWrapper,
  Subtitle,
  Title,
  SliderWrapper,
} from "./Services.Styled";
import { Container, Hero } from "@think-internet/ui-components";
import heroImage from "../../assets/image/services/hero.webp";
import Flag from "../SubComponents/Flag/Flag";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import { getUUID } from "../../utility";
import TextImageSplit from "./TextImageSplit/TextImageSplit";
import Slider from "./Slider/Slider";
import textImageSplitImages from "./textImageSplitImages";
import Card from "./Card/Card";
import { useState } from "react";
import SliderNavigation from "./SliderNavigation/SliderNavigation";
import { HiddenOnDesktop, HiddenOnMobile } from "../../Global.Styled";

const Services = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const elements = translation.services.textImage.sections;
  const [sliderIndex, setSliderIndex] = useState(0);

  const getSliderElements = () => {
    return elements.map((element, i) => (
      <Card
        key={getUUID()}
        index={i}
        isActive={i === sliderIndex}
        card={element}
        image={textImageSplitImages[i]}
      />
    ));
  };

  return (
    <Layout authRequired={false}>
      <StyledServices>
        <Hero img={heroImage}>
          <HeroContentContainer>
            <HeroContentWrapper>
              <Flag />
              <Subtitle>{translation.home.services.subtitle}</Subtitle>
              {translation.home.services.title.map((t) => (
                <Title key={getUUID()}>{t}</Title>
              ))}
            </HeroContentWrapper>
          </HeroContentContainer>
        </Hero>
        <HiddenOnMobile>
          <SliderNavigation
            elements={elements}
            index={sliderIndex}
            indexCallback={setSliderIndex}
          />
        </HiddenOnMobile>
        <Container>
          <HiddenOnMobile>
            <SliderWrapper>
              <Slider
                elements={getSliderElements()}
                index={sliderIndex}
                indexCallback={setSliderIndex}
              />
            </SliderWrapper>
          </HiddenOnMobile>
          <HiddenOnDesktop>
            {elements.map((section, i) => (
              <TextImageSplit
                data={section}
                image={textImageSplitImages[i]}
                key={getUUID()}
                index={i}
                revert={i % 2 === 1}
              />
            ))}
          </HiddenOnDesktop>
        </Container>
      </StyledServices>
    </Layout>
  );
};

export default Services;
