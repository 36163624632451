import {
  StyledHome,
  Logo,
  Split,
  SplitItem,
  Seperator,
  Background,
  Pagination,
  Page,
} from "./Home.Styled";
import logo from "../../assets/image/logo.svg";
import Layout from "../Layout/Layout";
import { useState } from "react";
import eltProtax from "../../assets/image/Home/elt-protax.webp";
import career from "../../assets/image/Home/career.png";
import services from "../../assets/image/Home/services.webp";
import locations from "../../assets/image/Home/locations.webp";
import contact from "../../assets/image/Home/contact.jpg";
import Template from "./Slides/Template/Template";
import { getUUID } from "../../utility";
import "./TransitionStyles.css";
import { useEffect } from "react";
import Modal from "../Modal/Modal";
import props from "../../redux/props";
import { useSelector } from "react-redux";

const Home = () => {
  const [open, setOpen] = useState(true);
  const [index, setIndex] = useState(0);
  const t = useSelector((s) => s[props.TRANSLATION]);
  const screens = [
    {
      uuid: getUUID(),
      background: eltProtax,
      component: <Template translationKey={"rise"} href="etl-protax" />,
    },
    {
      uuid: getUUID(),
      background: career,
      component: <Template translationKey={"career"} href="karriere" />,
    },
    {
      uuid: getUUID(),
      background: services,
      component: <Template translationKey={"services"} href="/leistungen" />,
    },
    {
      uuid: getUUID(),
      background: locations,
      component: <Template translationKey={"locations"} href="/standorte" />,
    },
    {
      uuid: getUUID(),
      background: contact,
      component: <Template translationKey={"contact"} href="/kontakt" />,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(index === screens.length - 1 ? 0 : index + 1);
    }, 6000);
    return () => clearInterval(interval);
  });

  return (
    <Layout authRequired={false}>
      <StyledHome>
        <Background
          key={screens[index].uuid}
          image={screens[index].background}
        />
        <Split>
          <SplitItem>{screens[index].component}</SplitItem>
          <Seperator />
          <SplitItem>
            <Logo src={logo} />
          </SplitItem>
        </Split>
        <Pagination>
          {screens.map((s, i) => (
            <Page
              key={getUUID()}
              onClick={() => setIndex(i)}
              active={i === index}
            />
          ))}
        </Pagination>
      </StyledHome>
      <Modal
        show={open}
        onClose={() => setOpen(false)}
        title={t.home.popup.title}
      >
        {t.home.popup.text.map((t) => (
          <div
            key={getUUID()}
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: t }}
          ></div>
        ))}
      </Modal>
    </Layout>
  );
};

export default Home;
