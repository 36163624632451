import styled from "styled-components";

export const StyledETLProtax = styled.div``;

export const HeroContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeroContentWrapper = styled.div`
  text-align: center;
`;
export const Title = styled.div`
  font-size: 150px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 150px;
`;
export const By = styled.div`
  font-size: 45px;
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  margin-bottom: 15px;
`;
export const Others = styled.div`
  font-size: 85px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 85px;
`;

export const Logo = styled.img`
  height: 50px;
`;
