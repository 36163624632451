import {
  StyledContactForm,
  Flag,
  CustomInput,
  InputWrapper,
  CustomTextarea,
  Label,
  ButtonWrapper,
  SubmitButton,
} from "./ContactForm.Styled";
import flag from "../../../assets/image/flag.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { callLambda } from "../../../fetch";
import toast, { TYPE } from "../../../toast";
import { awsSendMailLambdaPrefix } from "../../../utility";

const ContactForm = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [data, setData] = useState({});

  const setProp = (prop) => (value) => setData({ ...data, [prop]: value });

  const submit = async () => {
    const payload = { ...data, type: "contact" };
    const response = await callLambda(awsSendMailLambdaPrefix, payload);
    if (response && response.status === true) {
      toast(TYPE.SUCCESS, translation.contact.form.success);
      setData({});
    } else {
      toast(TYPE.ERROR, translation.contact.form.error);
    }
  };

  return (
    <StyledContactForm onSubmit={submit}>
      <Flag src={flag} />
      <Label>{translation.contact.form.label}</Label>
      <InputWrapper>
        <CustomInput
          value={data.name}
          onChange={setProp("name")}
          placeholder={translation.generic.name + "*"}
          required
        />
        <CustomInput
          value={data.mail}
          onChange={setProp("mail")}
          placeholder={translation.generic.mail + "*"}
          required
        />
        <CustomInput
          value={data.phone}
          onChange={setProp("phone")}
          placeholder={translation.generic.phone + "*"}
          required
        />
        <CustomTextarea
          value={data.message}
          onChange={setProp("message")}
          placeholder={translation.generic.message + "*"}
          rows="7"
          required
        />
        <ButtonWrapper>
          <SubmitButton type="submit" text={translation.contact.form.cta} />
        </ButtonWrapper>
      </InputWrapper>
    </StyledContactForm>
  );
};

export default ContactForm;
