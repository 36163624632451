import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  StyledReasons,
  ContentWrapper,
  CTA,
  Headline,
  PointWrapper,
} from "./Reasons.Styled";
import { getUUID } from "../../../utility";
import { Container } from "@think-internet/ui-components";
import Reason from "./Reason/Reason";

const Reasons = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <StyledReasons>
      <Container>
        <ContentWrapper>
          <CTA href="#positions">{translation.career.reasons.cta}</CTA>
          <Headline>{translation.career.reasons.headline1}</Headline>
          <Headline>{translation.career.reasons.headline2}</Headline>
          <PointWrapper>
            {translation.career.reasons.points.map((data, i) => (
              <Reason key={getUUID()} data={data} i={i} />
            ))}
          </PointWrapper>
        </ContentWrapper>
      </Container>
    </StyledReasons>
  );
};

export default Reasons;
