import { getUUID } from "../../../../utility";
import { StyledIndicators, Indicator } from "./Indicators.Styled";

const Indicators = ({ elementsLength, index, indexCallback }) => {
  return (
    <StyledIndicators>
      {Array.from([...new Array(elementsLength)]).map((e, i) => {
        return (
          <Indicator
            className="indicator"
            key={getUUID()}
            isActive={i === index}
            onClick={() => indexCallback(i)}
          />
        );
      })}
    </StyledIndicators>
  );
};

export default Indicators;
