import {
  StyledHeader,
  Toggle,
  MobileNavigation,
  CustomLink,
  Wrapper,
  Pages,
  StyledLegalToggle,
  LegalPopup,
  LegalTrigger,
} from "./Header.Styled";
import burger from "../../../assets/image/burger.png";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import props from "../../../redux/props";
import { setLocal } from "../../../redux/action/local";
import { getUUID } from "../../../utility";
import GetIcon from "../../SubComponents/GetIcon";
import Logo from "../../SubComponents/Logo/Logo";

const LegalToggle = () => {
  const [open, setOpen] = useState(false);
  // navigation with dropdown for two elements
  return (
    <StyledLegalToggle>
      <LegalTrigger onClick={() => setOpen(!open)}>
        <GetIcon name={"caret-down"} />
      </LegalTrigger>
      {open && (
        <LegalPopup>
          <CustomLink
            newTab={true}
            href="https://www.etl.de/newsletter/"
            text="Newsletter"
          />
          <CustomLink
            newTab={true}
            href="https://webapps.datev.de/duo-start"
            text="Datev Unternehmen Online"
          />
          <CustomLink
            newTab={true}
            href="https://apps.datev.de/ano/"
            text="Datev Arbeitnehmer Online"
          />
          <CustomLink href="/impressum" text="Impressum" />
          <CustomLink href="/datenschutz" text="Datenschutz" />
        </LegalPopup>
      )}
    </StyledLegalToggle>
  );
};

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const token = useSelector((s) => s.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    dispatch(setLocal(props.ACCOUNT, null));
    dispatch(setLocal(props.TOKEN, null));
    navigate("/");
  };

  const goHome = () => {
    if (token) {
      navigate("/dashboard/home");
    } else {
      navigate("/");
    }
  };

  return (
    <StyledHeader>
      <Wrapper>
        <Logo onClick={goHome} height="40px" dark />
        <Pages>
          {translation.header.pages
            .filter((p) => !p.mobileOnly)
            .map((p) => (
              <CustomLink key={getUUID()} href={p.href} text={p.name} />
            ))}
          <LegalToggle />
        </Pages>
        <Toggle src={burger} alt="Menu" onClick={() => setOpen(!open)} />
      </Wrapper>
      {open && (
        <MobileNavigation>
          {translation.header.pages.map((p) => (
            <CustomLink
              key={getUUID()}
              newTab={p.mobileOnly ? true : false}
              href={p.href}
              text={p.name}
            />
          ))}
          {token && (
            <CustomLink
              onClick={logout}
              text={translation.dashboard.logoutCTA}
            />
          )}
        </MobileNavigation>
      )}
    </StyledHeader>
  );
};

export default Header;
