import styled from "styled-components";

export const StyledDataProtection = styled.div`
  padding-bottom: 50px;
  ul {
    margin-left: 15px;
    color: ${({ theme }) => theme.color.font.secondary};
  }
  table {
    margin-left: 15px;
    color: ${({ theme }) => theme.color.font.secondary};
  }
  a {
    color: ${({ theme }) => theme.color.font.secondary};
  }
`;

export const HeroContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeroContentWrapper = styled.div`
  text-align: center;
`;
export const Title = styled.div`
  font-size: 70px;
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 70px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
    line-height: 50px;
  }
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const P = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  margin: 10px 0px;
`;

export const Split = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 50%;
  padding: 50px 15px;
  gap: 25px;
  box-sizing: content-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    padding: 50px 0px;
  }
`;

export const Bold = styled.span`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
`;
