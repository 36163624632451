import { Container, Hero } from "@think-internet/ui-components";
import Layout from "../Layout/Layout";
import {
  StyledImprint,
  HeroContentContainer,
  HeroContentWrapper,
  Title,
  Headline,
  P,
  Content,
  Link,
} from "./Imprint.Styled";
import heroImage from "../../assets/image/imprint/hero.png";
import Flag from "../SubComponents/Flag/Flag";
import { useSelector } from "react-redux";
import props from "../../redux/props";

const Imprint = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  return (
    <Layout authRequired={false}>
      <StyledImprint>
        <Hero img={heroImage}>
          <HeroContentContainer>
            <HeroContentWrapper>
              <Flag />
              <Title>{translation.imprint.headline}</Title>
            </HeroContentWrapper>
          </HeroContentContainer>
        </Hero>
        <Container>
          <Content>
            <Headline>ETL Protax GmbH</Headline>
            <P>Steuerberatungsgesellschaft</P>
            <P>Magnolienweg 3</P>
            <P>63741 Aschaffenburg</P>
            <br />
            <P>
              Telefon: <Link href="tel:+49937199800">+ 49 (0) 9371 99800</Link>
            </P>
            <P>
              E-Mail:{" "}
              <Link href="mailto:info@etl-protax.de">info@etl-protax.de</Link>
            </P>
            <Headline>Sitz der Gesellschaft</Headline>
            <P>Aschaffenburg</P>
            <Headline>Vertretung</Headline>
            <P>WP/StB Judith Liebler</P>
            <P>StB Christian Hoffmann</P>
            <P>StB Sascha Brömel</P>
            <Headline>USt-ID-Nr</Headline>
            <P>DE119817631</P>
            <Headline>Registereintrag</Headline>
            <P>AG Aschaffenburg</P>
            <P>HRB 15331</P>
            <Headline>Zuständige Steuerberaterkammer</Headline>
            <P>Steuerberaterkammer Nürnberg, Sitz Nürnberg</P>
            <Headline>Berufsstand</Headline>
            <P>Steuerberater</P>
            <P>
              Die gesetzliche Berufsbezeichnung
              Steuerberater/Steuerberatungsgesellschaft wurde in der
              Bundesrepublik Deutschland verliehen.
            </P>
            <P>
              Der Berufsstand der Steuerberater unterliegt im Wesentlichen den
              nachstehenden berufsrechtlichen Regelungen:
            </P>
            <P>a) Steuerberatungsgesetz (StBerG)</P>
            <P>
              b) Durchführungsverordnungen zum Steuerberatungsgesetz (DVStB)
            </P>
            <P>c) Berufsordnung (BOStB)</P>
            <P>d) Steuerberatervergütungsverordnung (StBVV)</P>
            <P>
              Die beruflichen Regelungen können online bei der{" "}
              <Link href="https://www.bstbk.de/" target="_blank">
                Bundessteuerberaterkammer
              </Link>{" "}
              eingesehen werden.
            </P>
            <Headline>Dienstleistungs- und Informationspflichten</Headline>
            <P>
              1. Die berufsrechtlichen Regelungen, denen wir i. S. v.
              § 3 DL-InfoV unterliegen, können auf der Homepage der
              Bundessteuerberaterkammer unter 
              <Link href="https://www.bstbk.de" target="_blank">
                www.bstbk.de
              </Link>
               (unter „Ihr Steuerberater/Berufsrecht“) abgerufen werden.
            </P>
            <P>
              2. Nach § 4 DL-InfoV weisen wir darauf hin, dass die zwischen uns
              getroffene Vergütungsvereinbarung Gültigkeit hat. Daneben gilt die
              StBVV. Die konkreten Gebühren bestimmen sich nach den Umständen
              des Einzelfalles. Wir verweisen auf die Homepage der
              Bundessteuerberaterkammer unter{" "}
              <Link href="https://www.bstbk.de" target="_blank">
                www.bstbk.de
              </Link>
              . Sie erhalten dort weitere Informationen unter „Ihr
              Steuerberater/Vergütung“.
            </P>
            <P>
              3. 
              <Link
                href="https://www.etl.de/zeigepdf/pdf/allgemeine_auftragsbedingungen"
                target="_blank"
              >
                Allgemeine Auftragsbedingungen
              </Link>
            </P>
            <P>4. Berufshaftpflichtversicherung:</P>
            <P>Zurich Insurance plc Niederlassung für Deutschland</P>
            <P>Riehler Straße 90</P>
            <P>50657 Köln</P>
            <P>
              Geltungsbereich: Der räumliche Geltungsbereich des
              Versicherungsschutzes besteht für Dienstleistungen in der
              Bundesrepublik Deutschland, ausländische Betriebsstätten werden
              nicht unterhalten.
            </P>
            <Headline>Teilnahme an einem Streitbeilegungsverfahren</Headline>
            <P>
              Wir nehmen an einem Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle im Sinne des
              Verbraucherstreitbeilegungsgesetzes (VBSG) nicht teil. Wir weisen
              jedoch darauf hin, dass die Möglichkeit besteht, bei
              Streitigkeiten betreffend das Mandatsverhältnis die für uns
              zuständige o.g. Steuerberaterkammer gemäß § 76 Abs. 2 Nr. 3 StBerG
              um Vermittlung anzurufen. Alternativ besteht die Möglichkeit, auf
              der Plattform der EU zur außergerichtlichen Streitbeilegung
              Beschwerde einzulegen. Diese finden Sie hier: 
              <Link href="http://ec.europa.eu/consumers/odr/" target="_blank">
                http://ec.europa.eu/consumers/odr/
              </Link>
              . Unsere E-Mail-Adresse finden Sie ebenfalls oben.
            </P>
            <Headline>Kooperation bei Rechtsdienstleistungen</Headline>
            <P>
              Dort wo Rechtsdienstleistungen erbracht werden, kooperieren wir
              mit den{" "}
              <Link href="https://www.etl-rechtsanwaelte.de/" target="_blank">
                ETL-Rechtsanwälten
              </Link>{" "}
              und bearbeiten mit ihnen die rechtlichen Aspekte der steuerlichen
              Gestaltung. Die ETL-Rechtsanwälte entwickeln im Einzelfall die
              erforderlichen Verträge und führen die rechtliche Beratung unter
              Beachtung der steuerlichen und wirtschaftlichen Ziele durch.
            </P>
            <Headline>Haftung für Inhalte</Headline>
            <P>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 TMG für eigene Inhalte auf diesen Seiten nach
              den allgemeinen Gesetzen verantwortlich. Diensteanbieter sind
              jedoch nicht verpflichtet, die von ihnen übermittelten oder
              gespeicherten fremden Informationen zu überwachen oder nach
              Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
              hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
              hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
              dem Zeitpunkt der Kenntnis einer konkreten Rechts­verletzung
              möglich. Bei Bekanntwerden von entsprechenden Rechts­verletzungen
              werden wir diese Inhalte umgehend entfernen.
            </P>
             <Headline>Haftung für Links</Headline>
            <P>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechts­verletzung nicht zumutbar. Bei Bekanntwerden von
              Rechts­verletzungen werden wir derartige Links umgehend entfernen.
            </P>
            <Headline>Urheberrecht</Headline>
            <P>
              Die Betreiber der Seiten werden stets die Urheberrechte anderer
              beachten bzw. auf selbst erstellte sowie lizenzfreie Werke
              zurückgreifen. Die durch die Seitenbetreiber erstellten Inhalte
              und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            </P>
            <Headline>Genderhinweis</Headline>
            <P>
              Gleichbehandlung und Gleichberechtigung sind uns überaus wichtig!
              Im Sinne einer besseren Lesbarkeit der Texte wählen wir für unsere
              Kommunikationskanäle jedoch entweder die männliche oder weibliche
              Form von personenbezogenen Hauptwörtern. Dies impliziert aber
              keinesfalls eine Benachteiligung des jeweils anderen Geschlechts,
              sondern ist im Sinne der sprachlichen Vereinfachung als
              geschlechtsneutral zu verstehen. Alle Menschen mögen sich von den
              Inhalten unserer Informationskanäle gleichermaßen angesprochen
              fühlen. Im Sinne der Gender Mainstreaming-Strategie der
              Bundesregierung vertreten wir ausdrücklich eine Politik der
              gleichstellungssensiblen Informationsvermittlung.
            </P>
          </Content>
        </Container>
      </StyledImprint>
    </Layout>
  );
};

export default Imprint;
