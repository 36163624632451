import styled from 'styled-components'

export const StyledDragAndDrop = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.radius};
  border: thin solid ${({ theme }) => theme.color.primary};
  padding: 40px;
  width: 100%;
  &:focus,
  &:active {
    outline: none;
  }
  ${({ theme }) => theme?.components?.draganddrop?.draganddrop};
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.color};
  text-align: center;
  ${({ theme }) => theme?.components?.draganddrop?.title};
`

export const Hint = styled.div`
  text-align: center;
  font-style: italic;
  color: ${({ theme }) => theme.color.font.color};
  font-size: ${({ theme }) => theme.font.size.sm};
  ${({ theme }) => theme?.components?.draganddrop?.hint};
`

export const FileList = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme?.components?.draganddrop?.filelist};
`

export const FileName = styled.div`
  display: block;
  margin-top: 5px;
  ${({ theme }) => theme?.components?.draganddrop?.filename};
`
